import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import theme from './theme';
import { FileProvider } from './utils/fileContext';
import { NotificationContextProvider } from './utils/userNotificationContext';
import { SearchContextProvider } from './utils/searchContext';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { I18nextProvider } from 'react-i18next';
import MomentUtils from '@date-io/moment';
import AppRouter from './AppRouter';
import './i18n';

export default function App() {
  const helmetContext = {};
  return (
    <FileProvider>
      <SearchContextProvider>
        <NotificationContextProvider>
          <HelmetProvider context={helmetContext}>
            <I18nextProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <AppRouter />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </I18nextProvider>
          </HelmetProvider>
        </NotificationContextProvider>
      </SearchContextProvider>
    </FileProvider>
  );
}
