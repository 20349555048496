import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTranslation, Trans } from 'react-i18next';

import timeline_person_icon from '../assets/image/timeline_person_icon.png';
import dollar_icon from '../assets/image/dollar_icon.png';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NearMeIcon from '@material-ui/icons/NearMe';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const TimelineComponent = ({ classes }) => {
  const { t } = useTranslation();
  return (
    <Timeline align="left" className={classes.timeline}>
      <TimelineItem className={classes.TimelineItem}>
        <TimelineSeparator>
          <TimelineDot className={classes.icon}>
            <img src={timeline_person_icon}></img>
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {t('item1Heaging')}
            </Typography>
            <Typography className={classes.content}></Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.TimelineItem}>
        <TimelineSeparator>
          <TimelineDot className={classes.icon}>
            <img src={dollar_icon}></img>
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {t('item2Heaging')}
            </Typography>
            <Typography
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: t('item2Content') }}
            ></Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.TimelineItem}>
        <TimelineSeparator>
          <TimelineDot className={classes.icon}>
            <SearchIcon />
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {t('item3Heaging')}
            </Typography>
            <Typography
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: t('item3Content') }}
            ></Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.TimelineItem}>
        <TimelineSeparator>
          <TimelineDot className={classes.icon}>
            <ShoppingCartIcon />
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {t('item4Heaging')}
            </Typography>
            <Typography
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: t('item4Content') }}
            ></Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.TimelineItem}>
        <TimelineSeparator>
          <TimelineDot className={classes.icon}>
            <NearMeIcon />
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {t('item5Heaging')}
            </Typography>
            <Typography
              className={classes.content}
              dangerouslySetInnerHTML={{ __html: t('item5Content') }}
            ></Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem className={classes.TimelineItem}>
        <TimelineSeparator>
          <TimelineDot className={classes.icon}>
            <ContactSupportIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              {t('itemManualHeading')}
            </Typography>
            <Typography className={classes.content}>
              <Trans i18nKey="itemManualContent">
                Check out our
                <a className={classes.link} href="/manuals">
                  manuals
                </a>
              </Trans>
            </Typography>
            <br />
            <br />
            <Typography className={classes.content}>
              {t('itemManualContent2')}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

const styles = (theme) => ({
  timeline: {
    // add your styling here
    float: 'left', // or you can use textAlign: 'left'
    textAlign: 'left',
  },
  icon: {
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#007AFF',
    borderRadius: '12.5px',
    '& > *': {
      color: '#ffffff',
      fontSize: '35px',
      width: 35,
    },
  },
  svg: {
    margin: 'auto',
  },
  paper: {
    padding: '6px 16px',
    boxShadow: 'none',
    backgroundColor: '#F2F2F7',
    fontWeight: 500,
  },
  title: {
    fontSize: '22px',
  },
  content: {
    fontSize: '18px',
  },
  secondaryTail: {
    height: '250px',
    backgroundColor: '#007AFF',
  },
  TimelineItem: {
    '&.MuiTimelineItem-missingOppositeContent:before': {
      flex: 0.3,
      [theme.breakpoints.down('sm')]: {
        flex: 0,
      },
    },
  },
  link: {
    textDecoration: 'none',
    color: '#007aff',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
});

export default withStyles(styles)(TimelineComponent);
