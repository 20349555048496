import React from 'react';

import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { Page } from '../components';

import { useTranslation } from 'react-i18next';

function EmailVerifiedPage() {
  const { t } = useTranslation();
  function render({ isLoggedIn }) {
    return isLoggedIn ? (
      <Redirect to="/search" />
    ) : (
      <Typography variant="h6">{t('email_verified')}</Typography>
    );
  }

  return <Page render={render} />;
}

export default EmailVerifiedPage;
