import {
  withStyles,
  Button,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm';

const ContactSection = ({ classes }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item md={12} xs={8}>
        <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
          {t('form_section_header')}
        </Typography>
        <Typography variant="h5" classes={{ h5: classes.sectionSubHeading }}>
          {t('form_section_subheader')}
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
        <div className={classes.heroText}>
          <Typography variant="h6" className={classes.heroSubHeading}>
            <b>Email</b> <br />
            <a href="mailto:sales@allcarparts.ae">sales@allcarparts.ae</a>
          </Typography>

          <Typography variant="h6" className={classes.contactData}></Typography>
          <Typography variant="h6" className={classes.heroSubHeading}>
            <b>{t('office_address')}</b> <br />
            Dubai South, Business Center,
            <br /> Building A3, 3rd floor, Dubai, UAE
          </Typography>
        </div>
      </Grid>
      <Grid item lg={6} md={6} sm={12}>
        <ContactForm />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => {
  const paddings = {
    [theme.breakpoints.up('md')]: {
      marginLeft: '15%',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  };
  return {
    sectionSubHeading: {
      marginBottom: '50px',
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    heroText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    contactData: {
      ...paddings,
      letterSpacing: '0px',
      color: '#000',
      marginTop: '16px',
      fontSize: '22px',
      fontWeight: 500,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '65%',
      },
    },
    heroSubHeading: {
      ...paddings,
      letterSpacing: '0px',
      color: '#000',
      marginTop: '16px',
      fontSize: '22px',
      fontWeight: 400,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '65%',
      },
    },
  };
};

export default withStyles(styles)(ContactSection);
