import querystring from 'querystring-es3';
import { withStyles } from '@material-ui/core/styles';
import * as quotationApi from '../api/quotation-api';
import * as deliveryApi from '../api/delivery-api';
import * as dictionaryApi from '../api/dictionary-api';
import { parseQuotationExcel } from '../utils/excel';
import { saveExcel } from '../utils/excel';
import { getCookie } from '../utils/cookies';
import { setCookie } from '../utils/cookies';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useFile } from '../utils/fileContext';
import { useSearchContext } from '../utils/searchContext';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import {
  Button,
  Tabs,
  Tab,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';
import {
  Page,
  SearchInput,
  SearchTable,
  ActionsGroup,
  Select,
  SelectFine,
  Spinner,
} from '../components';
import { Link } from 'react-router-dom';

import delivery_icon from '../assets/image/delivery_icon.png';
import currency_icon from '../assets/image/currency_icon.png';
import calendar_icon from '../assets/image/calendar_icon.png';
import download_icon from '../assets/image/download_icon.png';
import upload_icon from '../assets/image/upload_icon.png';
import AddDeliveryDialog from '../components/addDeliveryDialog';

const SearchBlock = ({ classes, history, location }) => {
  const { t } = useTranslation();
  const deliveryTermLimit = [
    { title: `1 ${t('days')}`, value: 1 },
    { title: `2 ${t('days')}`, value: 2 },
    { title: `4 ${t('days')}`, value: 4 },
    { title: `7 ${t('days')}`, value: 7 },
    { title: `14 ${t('days')}`, value: 14 },
    { title: `21 ${t('days')}`, value: 21 },
    { title: `30 ${t('days')}`, value: 30 },
    { title: `60 ${t('days')}`, value: 60 },
    { title: `180 ${t('days')}`, value: 180 },
  ];
  //const [isLoggedIn] = useState(!!getCookie('access_token'));
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [data, setData] = useState([]);
  const { setFile } = useFile();
  const {
    search,
    setSearch,
    deliveryTerm,
    setDeliveryTerm,
    deliveryTariff,
    setDeliveryTariff,
    currency,
    setCurrency,
  } = useSearchContext();

  const [tab, setTab] = useState(0);
  const [isFileUploading, setFileUploading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isAddDeliveryDialogOpen, setAddDeliveryDialogOpen] = useState(false);
  const [AddDeliveryError, setAddDeliveryError] = useState('');

  const [deliveryTariffList, setDeliveryTariffList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [lastQuotationId, setLastQuotationId] = useState(0);
  const initialized = useRef(false);

  const [userDelivery, setUserDelivery] = useState({
    tariff: '',
    active: true,
    countryCode: '',
    city: '',
    province: '',
    zipCode: '',
    street: '',
    building: '',
    flat: '',
    addInfo: '',
    carrierId: 0,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });

  const [showWarn, setShowWarn] = useState(0);

  const [progress, setProgress] = useState(false);

  async function getDeliveryTariffList() {
    const result = await deliveryApi.deliveries(true, null);

    setDeliveryTariffList(
      result.map((row) => ({
        title: row['text'],
        value: row['id'],
      }))
    );

    if (result.length !== 0) setDeliveryTariff(result[0].id);
  }

  async function getCurrencyList() {
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['code'],
        value: row['code'],
      }))
    );

    if (result.length !== 0) setCurrency(result[0].code);
  }

  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  const openFileDialog = useRef();

  const columns = useMemo(() => [
    { name: 'Brand', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Substituted', width: 15 },
    { name: 'Description', width: 20 },
    { name: 'Quantity', width: 10 },
    { name: 'Price', width: 10 },
    { name: 'WeightKg', width: 10 },
    { name: 'Reference', width: 15 },
    { name: 'Booking' },
    { name: 'Days' },
    { name: 'Currency' },
    { name: 'yourPrice' },
    { name: 'yourQuantity' },
    { name: 'yourDays' },
    { name: 'Comment' },
    { name: 'StockInfo' },
  ]);

  const outputData = useMemo(
    () =>
      data
        .filter((value) => value.confirmed)
        .map((value) => [
          value.brand,
          value.partNumber,
          value.price && value.inpPartNumber !== value.partNumber
            ? value.inpPartNumber
            : '',
          value.description,
          value.quantity &&
          (!value.available || value.quantity <= value.available)
            ? value.quantity
            : value.available,
          value.price,
          value.weightKg,
          value.yourReference,
          value.booking,
          value.days,
          value.currency,
          value.price && value.inpPrice < value.price ? value.inpPrice : '',
          value.price && value.inpQuantity > value.available
            ? value.inpQuantity
            : '',
          value.price && value.inpDays < value.days ? value.inpDays : '',
          value.errDesc || '',
          value.stock,
        ]),
    [data]
  );

  useEffect(() => {
    setLastQuotationId(null);
    setData([]);
    if (deliveryTariff) fetchDataBySearch();
  }, [location.search, deliveryTariff]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (!deliveryTariffList || !deliveryTariffList.length)
        getDeliveryTariffList().then();

      if (!currencyList || !currencyList.length) getCurrencyList().then();

      if (!countryList || !countryList.length) getCountryList().then();
    }
  }, []);

  useEffect(() => {
    fetchOnly().then();
  }, [currency]);

  useEffect(() => {
    setLastQuotationId(null);
    setData([]);
  }, [deliveryTerm, tab]);

  function handleFileUpload(event) {
    const file = event.target.files[0];
    setFile(file);
    history.push('/search');
  }

  async function fetchData(query, maxDays, onlyBest) {
    setData([]);
    setShowWarn(0);

    let result = [];

    setProgress(true);
    try {
      result = await quotationApi.process(query, maxDays, onlyBest, currency);
    } finally {
      setProgress(false);
    }

    let warn = 0;
    result.forEach((item) => {
      item.confirmed = true;
      if (warn < item.err) warn = item.err;
    });

    setShowWarn(warn);
    setData(result);

    if (result && result.length) {
      const { quotationId } = result[0];
      setLastQuotationId(quotationId);
    }
  }

  async function fetchOnly() {
    if (lastQuotationId) {
      let warn = 0;
      let result = [];

      setProgress(true);
      try {
        result = await quotationApi.select(lastQuotationId, currency);

        result.forEach((item) => {
          const oldItem = data.find((old) => old.id === item.id);
          if (oldItem) {
            item.confirmed = oldItem.confirmed;
            item.quantity = oldItem.quantity;

            if (item.err > warn) warn = item.err;
          }
        });
      } catch (e) {}

      setProgress(false);
      setShowWarn(warn);
      setData(result);
    }
  }

  function handleChangeCurrency(value) {
    setCurrency(value);
    setCookie('currency', value);
  }

  function fetchDataBySearch() {
    const params = querystring.parse(location.search.slice(1));
    if (params.part !== undefined) {
      setSearch(params.part);
      fetchData(
        [{ partNumber: params.part, delivery: deliveryTariff }],
        0,
        false
      ).then();
    }
  }

  function handleSearchInputChange(value) {
    setSearch(value);
  }

  function formatInput(value) {
    const numbers = value.replace(/\D/g, ''); // remove all non-digit characters
    return numbers.replace(/(\d{2})(?=\d)/g, '$1 '); // add space every two digits
  }

  function handleSearchInputSearch() {
    const params = querystring.parse(location.search.slice(1));

    const formattedSearch = search.replace(/\s/g, ''); // Remove spaces from the search input

    if (params.part === formattedSearch) {
      fetchDataBySearch();
    } else {
      history.push({
        pathname: '/search',
        search: `?part=${formattedSearch}`,
      });
    }
  }

  function handleTabChange(event, value) {
    setTab(value);
  }

  function handleFileOpen() {
    openFileDialog.current.click();
  }

  function handleDeliveryTermChange(value) {
    setDeliveryTerm(value);
  }

  function handleDeliveryTariffChange(value) {
    setDeliveryTariff(value);
  }

  function handleAddDeliveryDialogClose() {
    setAddDeliveryDialogOpen(false);
  }

  function handleAddDeliveryDialogOpen() {
    setAddDeliveryDialogOpen(true);
  }

  async function handleAddDelivery(deliveryData) {
    setUserDelivery(deliveryData);
    await deliveryApi.addDelivery(deliveryData);
    getDeliveryTariffList();
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.searchContainer}>
        <Grid item lg={3} sm={6} className={classes.gridItem}>
          <div className={classes.items}>
            <Typography variant="h5" className={classes.searchHeading}>
              {t('choose_search')}
            </Typography>
            <Tabs
              classes={{
                root: classes.tabs,
                indicator: classes.indicator,
              }}
              value={tab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  marginLeft: tab === 0 ? '2px' : '-2px',
                },
              }}
            >
              <Tab
                disableRipple
                label={
                  <StyledTypography>{t('by_part_number')}</StyledTypography>
                }
                classes={{ root: classes.tabRoot }}
                style={{
                  borderRadius: '10px 0 0 10px',
                }}
              />
              <Tab
                disableRipple
                label={
                  <StyledTypography>{t('by_excel_file')}</StyledTypography>
                }
                classes={{ root: classes.tabRoot }}
                style={{
                  borderRadius: '0 10px 10px 0',
                }}
              />
            </Tabs>
          </div>
        </Grid>
        <Grid item lg={3} sm={6} className={classes.gridItem}>
          {tab === 0 ? (
            <div className={classes.items}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('provide_part_number')}
              </Typography>
              <SearchInput
                value={search}
                onChange={handleSearchInputChange}
                onSearch={handleSearchInputSearch}
              />
            </div>
          ) : (
            <div className={classes.items}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('choose_delivery_method')}
              </Typography>
              <SelectFine
                classes={{ lang: classes.selectField }}
                items={deliveryTariffList}
                value={deliveryTariff}
                onChange={handleDeliveryTariffChange}
                icon={delivery_icon}
                userDelivery={isLoggedIn}
                onAddDelivery={handleAddDeliveryDialogOpen}
              />
            </div>
          )}
        </Grid>
        <Grid item lg={3} sm={6} className={classes.gridItem}>
          {tab === 0 ? (
            <div className={classes.items}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('choose_delivery_method')}
              </Typography>
              <SelectFine
                classes={{ lang: classes.selectField }}
                items={deliveryTariffList}
                value={deliveryTariff}
                onChange={handleDeliveryTariffChange}
                icon={delivery_icon}
                userDelivery={isLoggedIn}
                onAddDelivery={handleAddDeliveryDialogOpen}
              />
            </div>
          ) : (
            <div className={classes.items}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('choose_currency')}
              </Typography>
              <SelectFine
                classes={{ lang: classes.selectField }}
                items={currencyList}
                value={currency}
                onChange={handleChangeCurrency}
                icon={currency_icon}
              />
            </div>
          )}
        </Grid>
        <Grid item lg={3} sm={6} className={classes.gridItem}>
          {tab === 0 ? (
            <div className={classes.items}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('choose_currency')}
              </Typography>
              <SelectFine
                classes={{ lang: classes.selectField }}
                items={currencyList}
                value={currency}
                onChange={handleChangeCurrency}
                icon={currency_icon}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>

      {tab != 0 ? (
        <>
          <Divider variant="middle" />
          <Grid container className={classes.searchContainerByFile}>
            <Grid item lg={4}>
              <div className={classes.fileButtons}>
                <Typography variant="h5" className={classes.fileHeadings}>
                  {t('upload_csv_file')}
                </Typography>
                <Button
                  disabled={isFileUploading}
                  className={classes.leftButton}
                  variant="contained"
                  onClick={handleFileOpen}
                  startIcon={<img src={upload_icon} className={classes.icon} />}
                >
                  {t('upload')}
                </Button>
                <input
                  ref={openFileDialog}
                  type="file"
                  hidden
                  style={{ height: 0, width: 0 }}
                  accept=".xlsx"
                  onChange={handleFileUpload}
                />
              </div>
              <div className={classes.fileButtons}>
                <Typography variant="h5" className={classes.fileHeadings}>
                  {t('template')}
                </Typography>
                <Button
                  className={classes.rightButton}
                  variant="contained"
                  component="a"
                  href="/quote_template.xlsx"
                  download
                  startIcon={
                    <img src={download_icon} className={classes.icon} />
                  }
                >
                  {t('download')}
                </Button>
              </div>
            </Grid>
            <Grid item lg={2}>
              <div className={classes.deliveryTermContainer}>
                <Typography variant="h6" className={classes.searchHeading}>
                  {t('max_days')}
                </Typography>
                <SelectFine
                  classes={{ lang: classes.selectField }}
                  items={deliveryTermLimit}
                  value={deliveryTerm}
                  onChange={handleDeliveryTermChange}
                  icon={calendar_icon}
                />
              </div>
            </Grid>
            <Grid item lg={6}></Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {isAddDeliveryDialogOpen && (
        <AddDeliveryDialog
          error={AddDeliveryError}
          onClose={handleAddDeliveryDialogClose}
          onSubmit={handleAddDelivery}
          data={userDelivery}
          deliveryTariffList={deliveryTariffList}
          countryList={countryList}
        />
      )}
    </>
  );
};

/*
<SearchFilter
  filter={filter}
  disabled={!data.length}
  onFilterChange={handleFilterChange}
/>
*/

const StyledTypography = withStyles((theme) => ({
  root: {
    color: '#000',
    zIndex: 1000,
    fontWeight: 500,
    fontSize: '12px',
    ...theme.typography, // apply your theme's typography here
  },
}))(Typography);

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  const searchContainer = {
    backgroundColor: '#F2F2F7',
    minHeight: '200px',
    [theme.breakpoints.up('nr')]: {
      minHeight: '125px',
    },
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };
  return {
    tabs: {
      width: '275px',
      minHeight: '32px',
    },
    tabRoot: {
      backgroundColor: '#7676801F',
      opacity: 1,
      minHeight: '32px',
      [theme.breakpoints.up('sm')]: {
        width: '50%',
        height: '32px',
      },
    },
    tabSelected: {
      color: '#000',
    },
    tabContainer: {},
    indicator: {
      marginBottom: '2px',
      zIndex: '0',
      height: '88%',
      borderRadius: '10px',
      backgroundColor: '#fff',
      transition: 'left 0.3s',
      boxShadow:
        '0px 2.25px 6px rgba(0, 0, 0, 0.12), 0px 2.25px 0.75px rgba(0, 0, 0, 0.04)',
    },
    searchHeading: {
      maxWidth: '265px',
      fontWeight: '500',
      fontSize: '18px',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    gridItem: {
      [theme.breakpoints.up('nr')]: {
        flexBasis: '25% !important',
        maxWidth: '25% !important',
      },
      [theme.breakpoints.down('nr')]: {
        flexBasis: '50% !important',
        maxWidth: '50% !important',
      },
    },
    searchContainer: {
      ...searchContainer,
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        paddingLeft: '30px',
        justifyContent: 'flex-start',
        minHeight: '200px',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '400px',
        paddingLeft: '30px',
        justifyContent: 'flex-start',
      },
    },
    searchContainerByFile: {
      ...searchContainer,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: '210px',
        paddingLeft: '30px',
        justifyContent: 'flex-start',
      },
    },
    fileButtons: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '18px',
      marginTop: '16px',
      marginRight: '16px',
    },
    fileHeadings: {
      textAlign: 'left',
      fontSize: '16px',
      fontWeight: '400',
    },
    icon: {
      height: '14px',
      width: '14px',
    },
    deliveryTermLabel: {
      color: colors.deliveryTermLabel,
      marginRight: theme.spacing(1),
    },
    actionsGroup: {
      justifyContent: 'space-between',
    },
    selectField: {
      backgroundColor: '#fff',
      minWidth: '265px',
      height: '32px',
    },
    basketButton: {},
    excelButton: {
      backgroundColor: colors.excelButton.default,
      color: colors.excelButton.fontColor,
      '&:hover': {
        backgroundColor: colors.excelButton.hovered,
      },
    },
    tableContainer: theme.tableContainer,
    spinnerContainer: {
      ...searchContainer,
      marginTop: theme.spacing(1) * 6,
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    nextContainer: {
      marginLeft: theme.spacing(1),
    },
    downContainer: {
      marginTop: theme.spacing(1),
    },
    leftButton: {
      width: '162px',
      height: '32px',
      borderRadius: '10px',
      textTransform: 'capitalize',
      backgroundColor: '#007AFF',
      color: '#fff',
    },
    rightButton: {
      width: '162px',
      height: '32px',
      borderRadius: '10px',
      textTransform: 'capitalize',
      backgroundColor: '#007AFF15',
      color: '#007AFF',
    },
    whiteControl: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-input': {
        color: 'red',
      },
    },
    warnPanel: {
      borderRadius: '7px',
      backgroundColor: colors.table.selectedRow,
      textAlign: 'center',
      minHeight: 30,
      padding: '5px',
      marginBottom: theme.spacing(1),
    },
    warnLabel: {
      textTransform: 'uppercase',
      color: 'black',
    },
  };
};

export default withStyles(styles)(SearchBlock);
