import React from 'react';
import { withStyles, Tabs, Tab, Typography, Paper } from '@material-ui/core';
import classNames from 'classnames';
import { Select, SearchInput } from './index';
import { useTranslation } from 'react-i18next';
const SearchInputWithSelect = ({
  classes,
  className,
  selectItems,
  selectValue,
  searchValue,
  onSelectChange,
  onSearchChange,
  onSearchClick,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onSelectChange(newValue);
  };

  return (
    <div className={classNames(classes.container, className)}>
      {/* <Select
        className={classes.select}
        items={selectItems}
        value={selectValue}
        onChange={onSelectChange}
      /> */}

      <div className={classes.colContainer}>
        <Typography variant="h5" className={classes.searchHeading}>
          {t('choose_search')}
        </Typography>
        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.indicator,
          }}
          value={value}
          centered
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              marginLeft: value === 0 ? '2px' : '-2px',
            },
          }}
        >
          <Tab
            disableRipple
            label={<StyledTypography>{t('by_part_number')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
            style={{
              borderRadius: '10px 0 0 10px',
            }}
          />

          <Tab
            disableRipple
            label={<StyledTypography>{t('order_number')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
            style={{}}
          />
          <Tab
            disableRipple
            label={<StyledTypography>{t('reference')}</StyledTypography>}
            classes={{ root: classes.tabRoot }}
            style={{
              borderRadius: '0 10px 10px 0',
            }}
          />
        </Tabs>
      </div>

      {/* <Paper square>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab 
        label={
          <StyledTypography>{t('by_part_number')}</StyledTypography>
        }
        classes={{ root: classes.tabRoot }}
        />
        <Tab
        label={
          <StyledTypography>{t('order_number')}</StyledTypography>
        }
        classes={{ root: classes.tabRoot }}
        />
        <Tab
        label={
          <StyledTypography>{t('reference')}</StyledTypography>
        }   
        classes={{ root: classes.tabRoot }}
        />
      </Tabs>
    </Paper> */}

      {/* <Tabs
        className={classes.tabs}
        value={selectValue}
        onChange={onSelectChange}
      >
        {selectItems.map((item, index) => (
          <Tab key={index} value={item.value} label={item.title} />
        ))}
      </Tabs>  */}
      <div className={classes.colContainer}>
        <Typography variant="h5" className={classes.searchHeading}>
          {value === 0 ? t('provide_part_number') : ''}
          {value === 1 ? t('provide_order_number') : ''}
          {value === 2 ? t('provider_reference') : ''}
        </Typography>
        <SearchInput
          className={classes.searchInput}
          value={searchValue}
          onChange={onSearchChange}
          onSearch={onSearchClick}
        />
      </div>
    </div>
  );
};

const StyledTypography = withStyles((theme) => ({
  root: {
    color: '#000',
    zIndex: 1000,
    fontWeight: 500,
    fontSize: '12px',
    ...theme.typography, // apply your theme's typography here
  },
}))(Typography);

const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    select: {
      marginRight: theme.spacing(1),
    },
    searchInput: {
      width: 'initial',
    },
    tabs: {
      minWidth: '270px',
      margin: '0 auto',
      minHeight: '32px',
    },
    tabRoot: {
      backgroundColor: '#7676801F',
      opacity: 1,
      minHeight: '32px',
      [theme.breakpoints.up('sm')]: {
        minWidth: '125px',
        height: '32px',
      },
    },
    indicator: {
      marginBottom: '2px',
      zIndex: '0',
      height: '88%',
      borderRadius: '10px',
      backgroundColor: '#fff',
      transition: 'left 0.3s',
      boxShadow:
        '0px 2.25px 6px rgba(0, 0, 0, 0.12), 0px 2.25px 0.75px rgba(0, 0, 0, 0.04)',
    },
    searchHeading: {
      maxWidth: '100%',
      textAlign: 'left',
      fontWeight: '500',
      fontSize: '18px',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    colContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px',
      },
    },
  };
};

export default withStyles(styles)(SearchInputWithSelect);
