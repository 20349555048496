import React from 'react';

export const FileContext = React.createContext();

export const useFile = () => {
  return React.useContext(FileContext);
};

export function FileProvider({ children }) {
  const [file, setFile] = React.useState(null);

  const value = {
    file,
    setFile,
  };

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
}
