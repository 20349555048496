import React, { useState, createContext, useContext } from 'react';

export const SearchContext = createContext();

export const useSearchContext = () => {
  return useContext(SearchContext);
};

export const SearchContextProvider = ({ children }) => {
  const [search, setSearch] = useState('8532126020');
  const [deliveryTariff, setDeliveryTariff] = useState(0);
  const [deliveryTerm, setDeliveryTerm] = useState(4);
  const [currency, setCurrency] = useState('USD');

  const value = {
    search,
    setSearch,
    deliveryTerm,
    setDeliveryTerm,
    deliveryTariff,
    setDeliveryTariff,
    currency,
    setCurrency,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
