import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import CheckIcon from '@material-ui/icons/Check';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Tabs,
  Tab,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const ContactForm = ({ classes }) => {
  const [state, setState] = useState({
    toggleSwitch: false,
  });
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccess, setSuccess] = useState(false);

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
    setSuccess(false);
  };
  const handleUserEmailChange = (event) => {
    setEmail(event.target.value);
    setSuccess(false);
  };

  function handleTabChange(event, value) {
    setTab(value);
    setSuccess(false);
  }

  function handleSendEmail() {
    if (!userName.trim()) {
      alert('Name field must not be empty!');
      return;
    }
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      alert('Please enter a valid email address!');
      return;
    }
    const msg = {
      subject: tab === 0 ? 'Contact the manager' : 'Become a partner',
      name: userName,
      email: email,
    };
    emailjs
      .send('service_f1ebp9d', 'template_2hpn5af', msg, 'zXcHA2ljGG_XkxtlZ') //  emailjs.send('SERVICE_ID', 'TEMPLATE_ID', msg, 'PUBLIC KEY')
      .then(
        function (response) {
          // console.log('SUCCESS!', response.status, response.text);
          setUserName('');
          setEmail('');
          setSuccess(true);
        },
        function (error) {
          alert(t('error_something'));
        }
      );
  }

  const toggleComponent = (
    <Tabs
      classes={{
        root: classes.tabs,
        indicator: classes.indicator,
      }}
      value={tab}
      centered
      onChange={handleTabChange}
      TabIndicatorProps={{
        style: {
          marginLeft: tab === 0 ? '2px' : '-2px',
        },
      }}
    >
      <Tab
        disableRipple
        label={
          <StyledTypography>{t('formTab_contact_manager')}</StyledTypography>
        }
        classes={{ root: classes.tabRoot }}
        style={{
          borderRadius: '10px 0 0 10px',
        }}
      />
      <Tab
        disableRipple
        label={
          <StyledTypography>{t('formTab_become_partner')}</StyledTypography>
        }
        classes={{ root: classes.tabRoot }}
        style={{
          borderRadius: '0 10px 10px 0',
        }}
      />
    </Tabs>
  );

  return (
    <FormGroup className={classes.form}>
      <Typography variant="h4" component="div" className={classes.formHeader}>
        {t('form_header')}
      </Typography>

      <FormControl className={classes.formItem}>
        <TextField
          placeholder={t('form_field_name')}
          className={classes.formField}
          value={userName}
          onChange={handleUserNameChange}
        />
      </FormControl>

      <FormControl className={classes.formItem}>
        <TextField
          placeholder={t('form_field_email')}
          className={classes.formField}
          type="email"
          value={email}
          onChange={handleUserEmailChange}
        />
      </FormControl>

      <FormControlLabel
        className={classes.formToggle}
        control={toggleComponent}
      />

      <Button
        variant="contained"
        color={isSuccess ? 'secondary' : 'primary'}
        className={classes.formButton}
        type="submit"
        onClick={handleSendEmail}
      >
        {isSuccess ? <CheckIcon /> : t('form_button')}
      </Button>
      <Typography variant="body2" component="div">
        {t('form_data_not_processed')}
      </Typography>
    </FormGroup>
  );
};

const StyledTypography = withStyles((theme) => ({
  root: {
    color: '#000',
    zIndex: 1000,
    fontWeight: 500,
    fontSize: '12px',
    ...theme.typography, // apply your theme's typography here
  },
}))(Typography);

const styles = (theme) => ({
  form: {
    margin: '2rem auto 2rem auto',
    width: '65%',
    padding: '2rem',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F7',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  formItem: {
    margin: '1rem 0',
    width: '30rem',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  formToggle: {
    margin: '1rem 0',
    width: '30rem',
  },
  formField: {
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5rem',
  },
  formButton: {
    margin: '0.5rem 0',
    width: '30rem',
    borderRadius: '20px',
    minHeight: '50px',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  formHeader: {
    fontWeight: 500,
  },
  tabs: {
    minWidth: '30rem',
    minHeight: '30px',
  },
  tabRoot: {
    backgroundColor: '#7676801F',
    opacity: 1,
    minHeight: '30px',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      height: '30px',
    },
  },
  tabSelected: {
    color: '#000',
  },
  tabContainer: {},
  indicator: {
    marginBottom: '2px',
    zIndex: '0',
    height: '83%',
    borderRadius: '10px',
    backgroundColor: '#fff',
    transition: 'left 0.3s',
    boxShadow:
      '0px 2.25px 6px rgba(0, 0, 0, 0.12), 0px 2.25px 0.75px rgba(0, 0, 0, 0.04)',
  },
});
export default withStyles(styles)(ContactForm);
