import React, { useEffect, useState } from 'react';
import * as userApi from '../api/user-api';
import * as dictionaryApi from '../api/dictionary-api';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import {
  TextField,
  Button,
  Chip,
  withStyles,
  RadioGroup,
  Radio,
  MenuItem,
  Grid,
  Typography,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Captcha from '../components/Captcha';
import { Page } from '../components';
import { getCookie } from '../utils/cookies';
import { setCookie } from '../utils/cookies';

import person_circle_icon from '../assets/image/person_circle_icon.png';
import person_card_icon from '../assets/image/person_card_icon.png';

const ProfilePage = ({ classes }) => {
  const [formError, setFormError] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState('');
  const [success, setSuccess] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [userData, setUserData] = useState({
    username: '',
    phone: '',
    email: '',
    trn: '',
    currencyCode: '',
    countryCode: '',
    state: '',
    zipCode: '',
    city: '',
    street: '',
    building: '',
    flat: '',
    addInfo: '',
    contactPerson: '',
    contactPhone: '',
    contactEMail: '',
    licenseNumber: '',
    licenseDate: '',
    language: '',
  });

  useEffect(() => {
    const checkLogin = setInterval(
      () =>
        isLoggedIn !== !!localStorage.getItem('access_token') &&
        setIsLoggedIn(!isLoggedIn),
      500
    );

    try {
      getUserData();
      getCountryList();
      getCurrencyList();
    } catch (error) {
      // silence
    }

    return () => clearInterval(checkLogin);
  }, [isLoggedIn]);

  async function getCurrencyList() {
    setCurrencyList([]);
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }
  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  async function getUserData() {
    setUserData({
      username: '',
      phone: '',
      email: '',
      trn: '',
      currencyCode: '',
      countryCode: '',
      state: '',
      zipCode: '',
      city: '',
      street: '',
      building: '',
      flat: '',
      addInfo: '',
      contactPerson: '',
      contactPhone: '',
      contactEMail: '',
      licenseNumber: '',
      licenseDate: '',
      language: '',
    });

    const result = await userApi.getData();

    if (result !== null && result.length !== 0) {
      setCookie('currency', result[0].currencyCode);

      localStorage.setItem(
        'is_customer',
        result[0].isCustomer ? 'true' : 'false'
      );

      setUserData({
        username: result[0].username,
        phone: result[0].phone,
        email: result[0].email,
        trn: result[0].trn,
        currencyCode: result[0].currencyCode,
        countryCode: result[0].countryCode,
        state: result[0].state,
        zipCode: result[0].zipCode,
        city: result[0].city,
        street: result[0].street,
        building: result[0].building,
        flat: result[0].flat,
        addInfo: result[0].addInfo,
        contactPerson: result[0].contactPerson,
        contactPhone: result[0].contactPhone,
        contactEMail: result[0].contactEMail,
        licenseNumber: result[0].licenseNumber,
        licenseDate: result[0].licenseDate,
        language: result[0].language,
      });
    }
  }

  async function handlePasswordChange(pass) {
    if (!validatePassword(pass)) {
      return;
    }
    await userApi.changePassword(pass.oldPassword, pass.newPassword);
  }

  async function handleDataChange() {
    if (validateData()) {
      try {
        await userApi.setData(userData);
        setSuccess(true);
      } catch (error) {
        setFormError(error.message);
      }
    }
  }

  function handleSubmit() {
    if (
      password.oldPassword ||
      password.newPassword ||
      password.confirmPassword
    ) {
      handlePasswordChange(password);
    }
    handleDataChange();
  }

  function handleFieldChange(event) {
    const { target } = event;
    setUserData({ ...userData, [target.name]: target.value });
  }
  function handleFieldPassChange(event) {
    const { target } = event;
    setPassword({ ...password, [target.name]: target.value });
  }

  function handlePhoneChange(phoneValue) {
    setUserData((prevUserData) => ({
      ...prevUserData,
      phone: '+' + phoneValue,
    }));
  }

  const validatePassword = (password) => {
    const { oldPassword, newPassword, confirmPassword } = password;

    if (!oldPassword || !newPassword || !confirmPassword) {
      alert('All password fields must be filled');
      return false;
    }

    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return false;
    }

    // Check the length of the password
    if (newPassword.length < 8) {
      alert('Password must be at least 8 characters');
      return false;
    }

    // Check for special character
    if (!/[!@#$%^&*(),.?":{}|<>]/g.test(newPassword)) {
      alert('Password must contain at least one special character');
      return false;
    }

    // Check for a digit
    if (!/\d/.test(newPassword)) {
      alert('Password must contain at least one digit');
      return false;
    }

    return true;
  };

  const validateData = () => {
    let notValidField = '';

    for (let key of Object.keys(userData)) {
      // Fields are required if they are 'username', 'phone', or 'email'.
      const isRequiredField = ['username', 'phone', 'email'].includes(key);

      // Fields must not be empty if they are required or have been filled out.
      if (
        (isRequiredField || userData[key]) &&
        !/^[a-zA-Z0-9+@._\- ]*$/.test(userData[key])
      ) {
        notValidField = key;
        break; // Stop checking once we find an invalid field
      }
    }

    if (notValidField) {
      setInvalid(notValidField);
      setFormError(t('fill_latin'));
      return false; // If there is an invalid field, return false
    } else {
      setInvalid('');
      setFormError('');
      return true; // If all fields are valid, return true
    }
  };

  const submitError = error || formError;

  return (
    <Page>
      <Grid container style={{ marginTop: '30px' }}>
        <Grid container item md={6} sm={12} className={classes.formContainer}>
          <Grid item xs={12}>
            <form>
              {!!submitError && (
                <Chip
                  className={classes.errorChip}
                  color="secondary"
                  icon={<ErrorIcon />}
                  label={submitError}
                />
              )}
              <Grid item container xs={12}>
                <Grid item md={12}>
                  <Typography variant="h5" className={classes.searchHeading}>
                    <img src={person_circle_icon} className={classes.icon} />
                    {t('acc_data')}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    Email
                  </Typography>
                  <TextField
                    name="email"
                    type="email"
                    autoComplete="current-email"
                    className={classes.textField}
                    variant="outlined"
                    placeholder={'example@gmail.com'}
                    margin="normal"
                    fullWidth
                    value={userData.email}
                    onChange={handleFieldChange}
                    error={invalid === 'email'}
                  />
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={12}
                  xs={12}
                  className={classes.phoneContainer}
                >
                  <Typography variant="p" className={classes.textLabel}>
                    {t('contact_phone')}
                  </Typography>
                  <PhoneInput
                    preferredCountries={['ae', 'ua', 'kz']}
                    name="phone"
                    specialLabel={''}
                    country={'ae'}
                    containerClass={classes.phoneContainer}
                    //inputClass={classes.phoneInput}
                    inputStyle={{
                      borderRadius: '10px',
                      width: '280px',
                      height: '40px',
                    }}
                    value={userData.phone}
                    onChange={handlePhoneChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('old_password')}
                  </Typography>
                  <TextField
                    name="oldPassword"
                    className={classes.textField}
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    placeholder={'••••••••'}
                    margin="normal"
                    fullWidth
                    value={password.oldPassword}
                    onChange={handleFieldPassChange}
                    error={invalid === 'password'}
                  />

                  <List className={classes.list}>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_requiremests_head')}
                      />
                    </ListItem>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_at_least_chars')}
                      />
                    </ListItem>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_at_least_special')}
                      />
                    </ListItem>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_at_least_digit')}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('password')}
                  </Typography>
                  <TextField
                    name="newPassword"
                    className={classes.textField}
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    placeholder={'••••••••'}
                    margin="normal"
                    fullWidth
                    value={password.newPassword}
                    onChange={handleFieldPassChange}
                    error={invalid === 'password'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('confirm_password')}
                  </Typography>
                  <TextField
                    name="confirmPassword"
                    className={classes.textField}
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    placeholder={'••••••••'}
                    margin="normal"
                    fullWidth
                    value={userData.confirmPassword}
                    onChange={handleFieldPassChange}
                    error={invalid === 'confirmPassword'}
                  />
                </Grid>

                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('company_name')}
                  </Typography>
                  <TextField
                    name="username"
                    autoComplete=""
                    className={classes.textField}
                    variant="outlined"
                    placeholder={'All Car Parts1'}
                    margin="normal"
                    fullWidth
                    value={userData.username}
                    onChange={handleFieldChange}
                    error={invalid === 'username'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('choose_currency')}
                  </Typography>
                  <RadioGroup
                    name="currencyCode"
                    value={userData.currencyCode}
                    onChange={handleFieldChange}
                    row
                  >
                    {currencyList.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio color="inherit" />}
                        label={item.value}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                <Grid item lg={6} md={12} xs={12}></Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid
            item
            xs={12}
            style={{ marginTop: '45px' }}
            className={classes.buttonContainer}
          >
            {success ? (
              <Button
                className={classes.successButton}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled
              >
                Success
              </Button>
            ) : (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                {t('save')}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} sm={12} className={classes.formContainer}>
          <form>
            <Grid item container>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.searchHeading}>
                  <img src={person_card_icon} className={classes.icon} />
                  {t('personal_data')}
                </Typography>
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_country')}
                </Typography>
                <TextField
                  name="countryCode"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  select
                  placeholder={t('not_selected')}
                  value={userData.countryCode}
                  onChange={handleFieldChange}
                  required={true}
                  error={invalid === 'countryCode'}
                >
                  {countryList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_zip')}
                </Typography>
                <TextField
                  name="zipCode"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="05000"
                  fullWidth
                  value={userData.zipCode}
                  onChange={handleFieldChange}
                  error={invalid === 'zipCode'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_state')}
                </Typography>
                <TextField
                  name="state"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder={t('california')}
                  fullWidth
                  value={userData.state}
                  onChange={handleFieldChange}
                  error={invalid === 'state'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_city')}
                </Typography>
                <TextField
                  name="city"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder={t('san_francisco')}
                  fullWidth
                  value={userData.city}
                  onChange={handleFieldChange}
                  required={true}
                  error={invalid === 'city'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_street')}
                </Typography>
                <TextField
                  name="street"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="Main st."
                  fullWidth
                  value={userData.street}
                  onChange={handleFieldChange}
                  error={invalid === 'street'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_building')}
                </Typography>
                <TextField
                  name="building"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="54"
                  fullWidth
                  value={userData.building}
                  onChange={handleFieldChange}
                  error={invalid === 'building'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_flat')}
                </Typography>
                <TextField
                  name="flat"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  placeholder="32A"
                  value={userData.flat}
                  onChange={handleFieldChange}
                  error={invalid === 'flat'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_contact_person')}
                </Typography>
                <TextField
                  name="contactPerson"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  placeholder={t('contact_person')}
                  value={userData.contactPerson}
                  onChange={handleFieldChange}
                  error={invalid === 'contactPerson'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_tax')}
                </Typography>
                <TextField
                  name="trn"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="97612567"
                  fullWidth
                  value={userData.trn}
                  onChange={handleFieldChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              {success ? (
                <Button
                  className={classes.successButton}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled
                >
                  Success
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {t('save')}
                </Button>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.signUpDialog;
  return {
    formContainer: {
      padding: '30px',
      backgroundColor: '#F2F2F7',
      borderRadius: '20px',
      display: 'flex',
      maxWidth: '715px',
      margin: '0 auto 0 30px',
      '& > *': {},
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '370px',
      },
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    button: {
      ...theme.button.filled,

      width: '100%',
      marginRight: '10px',

      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
      '&.Mui-disabled': {
        ...theme.button.transparent,
      },
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      height: '32px',
      marginTop: '30px',
    },
    successButton: {
      color: '#fff',
      backgroundColor: '#34C759',
      width: '100%',
      borderRadius: '10px',
      '&.Mui-disabled': {
        backgroundColor: '#34C759aa',
        color: '#fff',
      },
    },
    list: {
      color: '#00000080',
      fontSize: '8px',
      padding: '0',
      margin: '0',
    },
    listTextRoot: {
      padding: '0',
      margin: '0',
      '& > *': {
        fontSize: '10px !important',
      },
    },
    labelField: {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
    },
    textField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
        backgroundColor: '#FFFFFF',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    phoneContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '10px',
      height: '100%',
    },
    phoneInput: {
      width: '240px',
      height: '40px',
      borderRadius: '10px',
    },
    searchHeading: {
      fontWeight: '500',
      fontSize: '22px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    textLabel: {
      fontSize: '14px',
      marginBottom: '10px',
    },
    errorChip: {
      color: colors.errorChip.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
    icon: {
      marginRight: '10px',
      width: '20px',
    },
  };
};

export default withStyles(styles)(ProfilePage);
