import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as userApi from '../api/user-api';
import { isEnterKey } from '../utils/keyEvents';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Chip,
  withStyles,
  Grid,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Page } from '../components';
import img_header from '../assets/image/image_header_reg.png';
import success_icon from '../assets/image/success_icon.png';

const LoginPage = ({ classes, open, error }) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [loginError, setLoginError] = useState('');
  //const [isLoggedIn] = useState(!!getCookie('access_token'));
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );

  const [passResetError, setPassResetError] = useState('');
  const [isEmptyEmail, setEmptyEmail] = useState(false);
  const [isLinkSent, setLinkSent] = useState(false);
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  function handleEnterPress(event) {
    if (isEnterKey(event)) {
      event.stopPropagation();
      handleLogin();
    }
  }

  function handleLogin() {
    if (!credentials.email || !credentials.password) return;
    onLogin(credentials.email, credentials.password);
  }

  async function onLogin(email, password) {
    try {
      setSubmitting(true);
      await userApi.login(email, password);
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setLoginError(error.message);
      setSubmitting(false);
    }
  }

  function handleBack() {
    setPasswordReset(false);
  }

  function handlePasswordReset() {
    setPasswordReset(true);
    if (credentials.email) onPasswordReset(credentials.email);
    else setEmptyEmail(true);
  }

  async function onPasswordReset(email) {
    try {
      await userApi.reset(email);
      setLinkSent(true);
    } catch (err) {
      setPassResetError(err.message);
    }
  }

  function handleFieldChange(event) {
    const { target } = event;
    setCredentials({ ...credentials, [target.name]: target.value });
  }

  const disabled =
    isSubmitting || !credentials.email || credentials.password.length < 7;

  return (
    <Page>
      {isLoggedIn && <Redirect to="/search" />}
      <Grid container justifyContent="center">
        <Grid xs={12} item>
          <div className={classes.header}>
            <Grid item className={classes.headerCell}>
              <Typography variant="h5" className={classes.headerTitle}>
                {t('header_log_in')}
              </Typography>
              <Typography variant="p" className={classes.headerSubTitle}>
                {t('header_first_time_here')}
              </Typography>
              <Link to="/register">
                <Button className={classes.headerButton}>
                  {t('tab_register')}
                </Button>
              </Link>
            </Grid>
          </div>
        </Grid>

        <Grid item className={classes.formContainer}>
          <form className={classes.form}>
            {!!error && (
              <Chip
                className={classes.errorChip}
                color="secondary"
                icon={<ErrorIcon />}
                label={error}
              />
            )}
            <Typography variant="h5" className={classes.searchHeading}>
              {isPasswordReset ? t('reset_password') : t('log_in_head')}
            </Typography>

            {isLinkSent ? (
              <img src={success_icon} className={classes.successIcon} />
            ) : (
              <>
                <Typography variant="p" className={classes.textLabel}>
                  Email
                </Typography>
                <TextField
                  name="email"
                  type="email"
                  autoComplete="current-email"
                  className={classes.emailTextField}
                  variant="outlined"
                  placeholder="example@gmail.com"
                  margin="normal"
                  fullWidth
                  value={credentials.email}
                  onChange={handleFieldChange}
                />
              </>
            )}

            {!isPasswordReset ? (
              <>
                <span className={classes.passwordContainer}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('password')}
                  </Typography>
                  <Typography
                    component="a"
                    className={classes.forgotPassword}
                    variant="caption"
                    onClick={handlePasswordReset}
                  >
                    {t('forgot_password')}
                  </Typography>
                </span>

                <TextField
                  name="password"
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  variant="outlined"
                  placeholder={'••••••••'}
                  margin="normal"
                  fullWidth
                  value={credentials.password}
                  onChange={handleFieldChange}
                  InputProps={{
                    endAdornment: (
                      <div className={classes.adornedEnd}>
                        <InputAdornment position="end">
                          <IconButton
                            className={classes.transparentIconButton}
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      </div>
                    ),
                  }}
                />
                {loginError && (
                  <Typography variant="body2" className={classes.errorLabel}>
                    {t('error_login')}
                  </Typography>
                )}
              </>
            ) : null}

            {isLinkSent && (
              <Typography
                className={classes.forgotPasswordMsg}
                variant="caption"
              >
                {t('reset_link_sent')} {credentials.email}
              </Typography>
            )}
          </form>
          {isPasswordReset && !isLinkSent ? (
            <Grid item xs={12} className={classes.buttonContainer}>
              <span>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleBack}
                >
                  {t('prev_step')}
                </Button>
              </span>
              <span>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  disabled={!credentials.email}
                  onClick={handlePasswordReset}
                >
                  {t('reset')}
                </Button>
              </span>
            </Grid>
          ) : !isLinkSent ? (
            <Grid item xs={12} className={classes.buttonContainer}>
              <span>
                <Link to="/register">
                  <Button
                    className={classes.transparentButton}
                    color="primary"
                    variant="contained"
                  >
                    {t('register_button')}
                  </Button>
                </Link>
              </span>
              <span>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleLogin}
                >
                  {t('log_in')}
                </Button>
              </span>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.loginDialog;
  return {
    formContainer: {
      boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.10)',
      padding: '30px',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '345px',
      margin: '0 auto',
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    forgotPassword: {
      display: 'inline-block',
      color: colors.forgotPassword.color,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    forgotPasswordMsg: {
      display: 'block',
      fontSize: '14px',
      textAlign: 'center',
      position: 'inherit',
      maxWidth: '340px',
      color: colors.forgotPassword.color,
    },
    textLabel: {
      fontSize: '14px',
      marginTop: '10px',
    },
    emailTextField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
      },
    },
    textField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
      },
    },
    passwordContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    searchHeading: {
      fontWeight: '500',
      fontSize: '22px',
      textAlign: 'center',
      paddingBottom: '15px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    successIcon: {
      width: '50px',
      margin: '10px auto',
    },
    header: {
      margin: '60px auto',
      height: '192px',
      width: '85%',
      borderRadius: '20px',
      padding: '0',
      overflow: 'hidden',
      backgroundImage: `url(${img_header})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: '0px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%',
      },
    },
    headerCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'clamp(340px,30%,50%)',
      height: '100%',
      backdropFilter: 'blur(20px)',
      padding: '30px 5px 15px 30px',
    },
    headerTitle: {
      fontWeight: '500',
      fontSize: '40px',
      lineHeight: '40px',
      fontWeight: '600',
      color: '#FFFFFF',
      textAlign: 'left',
      paddingBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    headerSubTitle: {
      color: '#FFFFFF',
      fontWeight: '400',
      fontSize: '12px',
    },
    headerButton: {
      color: '#FFFFFF',
      minWidth: '80px',
      height: '24px',
      background: '#ffffff40',
      fontSize: '12px',
      margin: '10px 0',
      borderRadius: '100px',
      fontWeight: '400',
      textTransform: 'capitalize',
    },
    button: {
      ...theme.button.filled,
      minWidth: '85px',
      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
    },
    transparentButton: {
      ...theme.button.transparent,
      minWidth: '85px',
      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: '20px',
    },
    errorLabel: {
      textAlign: 'center',
      color: '#FF3B30',
      marginBottom: '0',
      paddingBottom: '0',
      lineHeight: '10px',
    },
    errorChip: {
      color: colors.forgotPassword.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
    adornedEnd: {
      backgroundColor: '#e6f2ff', //'#fff',//theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      height: 53,
      width: 50,
      marginLeft: -13,
      marginRight: -14,
      borderRadius: '10px',
    },
    transparentIconButton: {
      backgroundColor: '#e6f2ff',
      '&:hover': {
        backgroundColor: '#e6f2ff',
      },
      height: '53px',
    },
  };
};

export default withStyles(styles)(LoginPage);
