import React, { Fragment, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { withStyles } from '@material-ui/core/styles';
import { ShipmentsBoxTable } from './index';
import { useTranslation } from 'react-i18next';

const styles = (theme) => {
  const colors = theme.palette.custom.shipmentsPage;

  return {
    table: {
      width: '100%',
    },
    tableHead: {
      backgroundColor: '#F2F2F7',
    },
    tableContainer: {
      margin: '0 auto',
      width: '90%',
      boxShadow: 'none',
      maxHeight: '100%',
      overflowY: 'auto',
      border: '1px solid #00000040',
      borderRadius: '10px',
    },
    unfoldedRow: {
      backgroundColor: colors.table.unfoldedRow,
    },
    boxTableContainer: {
      paddingRight: '0px !important',
    },
    iconButton: {
      padding: 0,
      color: '#000000',
    },
  };
};

const ShipmentsTable = ({ classes, boxes, boxItems, onUnfold }) => {
  const { t } = useTranslation();

  const [unfolded, setUnfolded] = useState({});

  function handleUnfold(boxId) {
    const isUnfolded = unfolded[boxId];
    setUnfolded({ ...unfolded, [boxId]: !isUnfolded });
    !isUnfolded && onUnfold && onUnfold(boxId);
  }

  const currency = boxes.length ? boxes[0].currency : '';

  return (
    <TableContainer
      component={Paper}
      variant="rounded"
      className={classes.tableContainer}
    >
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell />
            <TableCell>{t('place')}</TableCell>
            <TableCell>{t('weight_kg')}</TableCell>
            <TableCell>{t('length_m')}</TableCell>
            <TableCell>{t('height_m')}</TableCell>
            <TableCell>{t('width_m')}</TableCell>
            <TableCell>{t('volume_kg')}</TableCell>
            <TableCell>{t('value_with_currency', { currency })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boxes.map((value, i) => (
            <Fragment key={value.boxId}>
              <TableRow
                className={unfolded[value.box] ? classes.unfoldedRow : ''}
                style={{ backgroundColor: i % 2 === 0 ? '#FBFBFB' : '#FFFFFF' }}
              >
                <TableCell padding="checkbox">
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleUnfold.bind(null, value.boxId)}
                  >
                    {unfolded[value.boxId] ? (
                      <RemoveCircleIcon />
                    ) : (
                      <AddCircleIcon />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>{value.boxNumber}</TableCell>
                <TableCell>{value.weightKg}</TableCell>
                <TableCell>{value.lengthM}</TableCell>
                <TableCell>{value.heightM}</TableCell>
                <TableCell>{value.widthM}</TableCell>
                <TableCell>{value.volumeKg}</TableCell>
                <TableCell>{value.amount}</TableCell>
              </TableRow>
              {unfolded[value.boxId] && (
                <TableRow>
                  <TableCell colSpan={8} className={classes.boxTableContainer}>
                    <ShipmentsBoxTable
                      data={boxItems.filter(
                        (item) => item.boxId === value.boxId
                      )}
                    />
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withStyles(styles)(ShipmentsTable);
