import React, { useEffect, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  TablePagination,
} from '@material-ui/core';
import * as orderApi from '../api/order-api';
import {
  Page,
  OrdersTable,
  ActionsGroup,
  SearchInputWithSelect,
  Spinner,
} from '../components';
import { Redirect } from 'react-router-dom';
import { saveExcel } from '../utils/excel';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import download_icon from '../assets/image/download_icon.png';
import ChangeDeliveryDialog from '../components/ChangeDeliveryDialog';
import RefusalDialog from '../components/RefusalDialog';

const OrdersPage = ({ classes }) => {
  const { t } = useTranslation();

  const filterTypes = [
    { title: t('part_number'), value: 1 },
    { title: t('order_number'), value: 2 },
    { title: t('reference'), value: 3 },
  ];

  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [data, setData] = useState([]);
  const [filterType, setFilterType] = useState(2);
  const [successItems, setSuccessItems] = useState([]);
  const [errorItems, setErrorItems] = useState([]);
  const [errorItemIds, setErrorItemIds] = useState([]);
  const [filter, setFilter] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refusalDialogOpen, setRefusalDialogOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  async function fetchData(query) {
    setProgress(true);
    try {
      const result = await orderApi.states(query);
      setData(result);
    } finally {
      setProgress(false);
    }
  }

  useEffect(() => {
    if (isLoggedIn) fetchData();
  }, [isLoggedIn]);

  function handleSearchTypeChange(value) {
    setFilterType(value + 1);
  }

  function handleFilterChange(value) {
    setFilter(value.trim().toLowerCase());
  }

  function handleSearch() {
    setData([]);
    const query = {};

    if (filter)
      switch (filterType) {
        case 1:
          query.partNumber = filter;
          break;
        case 2:
          query.id = filter;
          break;
        case 3:
          query.yourReference = filter;
          break;
        case 4:
          query.yourOrderId = filter;
          break;
      }
    fetchData(query);
  }

  function handleAllClick() {
    setFilter('');
    setData([]);
    fetchData();
  }

  const columns = useMemo(() => [
    { name: 'Order', width: 10 },
    { name: 'Date', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Brand', width: 15 },
    { name: 'Booking', width: 10 },
    { name: 'Delivery', width: 10 },
    { name: 'Order Price', width: 10 },
    { name: 'Sale Price', width: 10 },
    { name: 'Currency', width: 10 },
    { name: 'InWork', width: 10 },
    { name: 'Ordered', width: 10 },
    { name: 'Confirmed', width: 10 },
    { name: 'To Dispatch', width: 10 },
    { name: 'Dispatched', width: 10 },
    { name: 'Refused', width: 10 },
    { name: 'Reference', width: 20 },
    { name: 'Description', width: 50 },
    { name: 'State Date', width: 15 },
    { name: 'Shipment', width: 20 },
  ]);

  const outputData = useMemo(
    () =>
      data.map((value) => [
        value.orderId,
        value.orderDate?.slice(0, 10),
        value.partNumber,
        value.brand,
        value.catalog,
        `${value.delivery} (${value.tariff})`,
        value.priceOrder,
        value.priceSale,
        value.currency,
        value.inwork,
        value.ordered,
        value.purchased,
        value.accepted,
        value.shipped,
        value.refused,
        value.yourReference,
        value.answer,
        value.stateDate.slice(0, 16).replace('T', ' '),
        value.shipment,
      ]),
    [data]
  );

  async function handleToExcel() {
    await saveExcel('OrdersStates', columns, outputData);
  }

  async function handleDeliveryChange(delivery) {
    setIsLoading(true);
    const orderIds = selectedRows;
    try {
      const res = await orderApi.changeDelivery(
        orderIds,
        Number(delivery.sorting)
      );
      setDialogOpen(false);

      const successItems = res.filter((item) => !item.error);
      const errorItems = res.filter((item) => item.error);

      const newDataMap = new Map(successItems.map((item) => [item.id, item]));
      const updatedData = data.map((item) => {
        return newDataMap.has(item.id) ? newDataMap.get(item.id) : item;
      });

      setData(updatedData);
      setSuccessItems(successItems.map((item) => item.id));
      setErrorItemIds(errorItems.map((item) => item.id));
      setErrorItems(errorItems);
    } catch (error) {
      console.log('Error changing delivery:', error);
    }
    setIsLoading(false);
  }

  async function handleSendRefusals() {
    setIsLoading(true);
    const orderIds = selectedRows;
    try {
      const res = await orderApi.sendRefusals(orderIds);
      const successItems = res.filter((item) => !item.error);
      const newDataMap = new Map(successItems.map((item) => [item.id, item]));
      const updatedData = data.map((item) => {
        return newDataMap.has(item.id) ? newDataMap.get(item.id) : item;
      });
      setData(updatedData);
      setRefusalDialogOpen(false);
      setSelectedRows([]);
    } catch (error) {}
    setIsLoading(false);
  }

  return (
    <Page>
      <ChangeDeliveryDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        itemsNumber={selectedRows.length}
        onDeliveryChange={handleDeliveryChange}
        isLoading={isLoading}
      />
      <RefusalDialog
        open={refusalDialogOpen}
        onClose={() => setRefusalDialogOpen(false)}
        onConfirm={handleSendRefusals}
        loading={isLoading}
      />
      <div>
        <Helmet>
          <title>Orders - AllCarParts - track and control your orders</title>
          <meta
            name="description"
            content="Orders states tracking and control"
          ></meta>
        </Helmet>
      </div>

      {!isLoggedIn && <Redirect to="/search" />}
      <ActionsGroup className={classes.actionsGroup}>
        <Grid container>
          <Grid item xs={10} className={classes.actionsLeft}>
            <SearchInputWithSelect
              className={classes.searchInput}
              selectItems={filterTypes}
              selectValue={filterType}
              searchValue={filter}
              onSelectChange={handleSearchTypeChange}
              onSearchClick={handleSearch}
              onSearchChange={handleFilterChange}
            />
            <div className={classes.buttonContainer}>
              <Button
                disableRipple
                variant="contained"
                color="default"
                className={classes.button}
                onClick={handleAllClick}
              >
                {t('all')}
              </Button>
              <ButtonGroup
                variant="contained"
                color="primary"
                className={classes.buttonGroup}
                disableElevation
                size="small"
                disableRipple
                disabled={!selectedRows.length}
              >
                <Button
                  onClick={() => setDialogOpen(true)}
                  style={{
                    textTransform: 'capitalize',
                    minWidth: '100px',
                  }}
                >
                  {t('change_delivery')}
                </Button>
                <Button
                  onClick={() => setRefusalDialogOpen(true)}
                  style={{
                    textTransform: 'capitalize',
                    minWidth: '70px',
                  }}
                >
                  {t('send_refusal')}
                </Button>
              </ButtonGroup>
            </div>
          </Grid>
          <Grid item xs={2} className={classes.actionsRight}>
            <Button
              disabled={!data.length}
              variant="contained"
              // color="primary"
              className={classes.exportButton}
              onClick={handleToExcel}
            >
              <img className={classes.excelLogo} alt="" src={download_icon} />
              {t('export')}
            </Button>
          </Grid>
        </Grid>
      </ActionsGroup>
      {errorItemIds.length ? (
        <div className={classes.warnPanel}>
          <Typography className={classes.warnLabel}>
            {t('deliveryChangeErrors')}
          </Typography>
        </div>
      ) : null}
      {progress ? (
        <div className={classes.spinnerContainer}>
          {progress ? <Spinner /> : null}
        </div>
      ) : (
        <div className={classes.tableContainer}>
          <OrdersTable
            data={data}
            onSelect={setSelectedRows}
            selectedRows={selectedRows}
            page={page}
            successItems={successItems}
            errorItems={errorItems}
            errorItemIds={errorItemIds}
            clearSuccessItems={() => {
              setSuccessItems([]);
              setErrorItems([]);
              setErrorItemIds([]);
            }}
          />
        </div>
      )}
      {data.length ? (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={500}
          page={page}
          onPageChange={handleChangePage}
          style={{ margin: '0 auto' }}
          backIconButtonProps={{ className: classes.paginationButton }}
          nextIconButtonProps={{ className: classes.paginationButton }}
          labelDisplayedRows={({ from, to, count, page }) =>
            `${t('page')} ${page + 1} / ${Math.floor(count / 500) + 1}`
          }
        />
      ) : null}
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.ordersPage;
  const mainContainer = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.tabSelectedBackground,
    height: '72px',
    minHeight: '72px',

    marginBottom: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit}px`,
  };
  return {
    tableContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 'calc(100vh - 275px)',
      width: '95%',
      flexGrow: 1,
      margin: '0 auto',
      overflowX: 'auto',
    },
    actionsGroup: {
      justifyContent: 'flex-end',
      minHeight: '125px',
      backgroundColor: '#7676801F',
      padding: '20px 16px',
      margin: '1rem auto 1rem auto',
      width: '90%',
      borderRadius: '20px',
      background: '#F2F2F7',
      [theme.breakpoints.up('xl')]: {
        width: '95%',
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      flexWrap: 'wrap',
      gap: 5,
    },
    paginationButton: {
      color: '#F2F2F7',
      backgroundColor: '#007AFF',
      padding: '5px',
      margin: '0 2px',
      '&:hover': {
        backgroundColor: '#007AFF90',
      },
    },
    actionsLeft: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'end',
      [theme.breakpoints.up('sm')]: {
        '& > *': {
          marginRight: '30px',
        },
      },
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    actionsRight: {
      flex: '1 0 0px',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'flex-end',
    },
    buttonGroup: {
      borderRadius: '10px',
      overflow: 'hidden',
      marginLeft: '8px',
      height: '32px',
    },
    button: {
      ...theme.button.filled,
      width: '180px',
      height: '32px',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px',
      },
      overflow: 'hidden',
    },
    excelButton: {
      backgroundColor: colors.excelButton.default,
      color: colors.excelButton.fontColor,
      '&:hover': {
        backgroundColor: colors.excelButton.hovered,
      },
    },
    searchInput: {
      marginLeft: theme.spacing(1),
    },
    spinnerContainer: {
      ...mainContainer,
      marginTop: theme.spacing(1) * 6,
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    exportButton: {
      ...theme.button.transparent,
      height: '32px',
      borderRadius: '10px',
    },
    excelLogo: {
      width: '16px',
      height: '16px',
      marginRight: theme.spacing(1),
    },
    warnPanel: {
      borderRadius: '7px',
      backgroundColor: '#F2F2F7',
      textAlign: 'center',
      minHeight: 30,
      padding: '5px',
      marginBottom: theme.spacing(1),
    },
    warnLabel: {
      textTransform: 'uppercase',
      color: '#FF3B30',
    },
  };
};

export default withStyles(styles)(OrdersPage);
