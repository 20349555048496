import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import SelectTopUpMethodDialog from './SelectTopUpMethodDialog';
import BalanceTopupDialog from './BalanceTopupDialog';

import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import card_icon from '../assets/image/card_icon.png';
import delivery_icon_black from '../assets/image/delivery_icon_black.png';
import dollar_icon_black from '../assets/image/dollar_icon_black.png';
import cart_icon_black from '../assets/image/cart_icon.png';
import currency_icon from '../assets/image/currency_icon.png';
import dollar_icon_black_circle from '../assets/image/dollar_icon_black_circle.png';

const BalanceTotalTable = ({ classes, data }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [topupOpen, setTopupOpen] = useState(false);
  const [onlineTopupOpen, setOnlineTopupOpen] = useState(false);

  const handleTopup = () => {
    setTopupOpen(true);
  };

  const handleTopupClose = () => {
    setTopupOpen(false);
  };

  const handleOnlineTopup = () => {
    setTopupOpen(false);

    if (data && data.topupReference /* === 'TEST'*/) setOnlineTopupOpen(true);
  };

  const handleOnlineTopupClose = () => {
    setOnlineTopupOpen(false);
  };

  const currency = data.currency ? data.currency : '';
  const tableData = [
    {
      header: t('balance_with_currency', { currency }),
      value: data.balance,
      buttonText: t('topup'),
      buttonAction: handleTopup,
      icon: card_icon,
    },
    {
      header: t('in_orders'),
      value: data.inworkOrders,
      buttonText: t('proceed'),
      buttonAction: () => {
        history.push('/orders');
      },
      icon: currency_icon,
    },
    {
      header: t('in_cart'),
      value: data.readyForOrder,
      buttonText: t('proceed'),
      buttonAction: () => {
        history.push('/cart');
      },
      icon: cart_icon_black,
    },
    {
      header: t('pay_for_cart'),
      value: data.topupForOrder,
      buttonText: t('topup'),
      buttonAction: handleTopup,
      icon: dollar_icon_black,
    },
    {
      header: t('ready_to_ship'),
      value: data.readyForShipment,
      buttonText: t('proceed'),
      buttonAction: () => {
        history.push('/dispatch');
      },
      icon: delivery_icon_black,
    },
    {
      header: t('pay_to_ship'),
      value: data.topupForShipment,
      buttonText: t('topup'),
      buttonAction: handleTopup,
      icon: dollar_icon_black_circle,
    },
  ];
  return (
    <Grid container spacing={2} className={classes.container}>
      {tableData.map((column, index) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2}
          key={index}
          className={classes.col}
        >
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="flex-start">
                <img src={column.icon} className={classes.icon} />
                <Typography
                  variant="h6"
                  component="div"
                  classes={{ h6: classes.cardTitle }}
                >
                  {column.header}
                </Typography>
              </Box>
              <Typography variant="body2" className={classes.cardContent}>
                {column.value}
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                size="small"
                className={classes.actionButton}
                onClick={column.buttonAction}
              >
                {column.buttonText}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}

      <SelectTopUpMethodDialog
        open={topupOpen}
        handleClose={handleTopupClose}
        handleOnlineTopup={handleOnlineTopup}
        classes={classes}
      ></SelectTopUpMethodDialog>

      <BalanceTopupDialog
        open={onlineTopupOpen}
        onClose={handleOnlineTopupClose}
        topupId={data.topupId}
        classes={classes}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  table: {
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  col: {
    maxWidth: '200px',
  },
  container: {
    marginTop: '1rem',
    display: 'flex',
    flexBasis: '95%',
    justifyContent: 'center',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '95%',
      gap: '0px',
    },
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'space-around',
    },
  },
  card: {
    padding: '1px',
    boxShadow: 'none',
    height: '100%',
    backgroundColor: '#F2F2F7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    fontWeight: 500,
    fontSize: '20px',
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.2,
  },

  actionButton: {
    ...theme.button.transparent,
    width: '95%',
    height: '32px',
  },
  dialogButton: {
    ...theme.button.transparent,
  },
  dialogTitle: {
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: '18px',
    width: '18px',
    marginRight: '10px',
  },
});

export default withStyles(styles)(BalanceTotalTable);
