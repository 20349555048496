import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SelectTopUpMethodDialog({
  open,
  handleClose,
  handleOnlineTopup,
  classes,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      handleOnlineTopup={handleOnlineTopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitle,
        }}
        id="alert-dialog-title"
      >
        {t('choose_payment_method')}
      </DialogTitle>
      <DialogActions>
        <Button
          className={classes.dialogButton}
          onClick={handleOnlineTopup}
          color="primary"
        >
          {t('online_payment_method')}
        </Button>

        <Button
          className={classes.dialogButton}
          component={Link}
          to="/bank"
          color="primary"
          autoFocus
        >
          {t('bank_transfer_payment_method')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectTopUpMethodDialog;
