import React, { useEffect } from 'react';
import {
  Button,
  Link,
  Drawer,
  IconButton,
  isWidthDown,
  useMediaQuery,
} from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from '../utils/userNotificationContext';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const NavBar = ({ classes, customerCode, isLoggedIn, onLogout, width }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t, i18n } = useTranslation();
  const {
    cartNotification,
    balanceData,
    totals,
    newsCardsData,
    fetchCartData,
    fetchBalanceData,
    fetchOrderData,
    fetchTotals,
    fetchNewsList,
  } = useNotificationContext();

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalanceData();
      fetchCartData();
      fetchOrderData();
      fetchTotals();
    }
    fetchNewsList();
  }, [i18n.language]);
  const isMobile = isWidthDown('sm', width);
  const isCustomBreakpointUp = useMediaQuery(
    `(min-width:${theme.breakpoints.values.bg}px)`
  );
  const listShirnk =
    i18n.language === 'uk'
      ? !isCustomBreakpointUp && !isMobile
      : !isWidthUp('lg', width) && !isMobile;

  const buttonVariant = 'contained'; //isMobile ? 'text' : 'contained';
  return (
    <>
      <div className={classes.headerList}>
        <Link href="/" className={classes.link}>
          {t('home')}
        </Link>

        <Link href="/news" className={classes.link}>
          {t('news')}
          {newsCardsData.length ? (
            <span className={classes.notificationBubble}></span>
          ) : null}
        </Link>

        <Link href="/search" className={classes.link}>
          {t('search')}
        </Link>

        <Link
          href="/orders"
          className={isLoggedIn ? classes.link : classes.linkDisabled}
        >
          {t('orders')}
          {/* {orderData.length && isLoggedIn ? (
            <span className={classes.notificationBubble}></span>
          ) : null} */}
        </Link>

        <Link
          href="/dispatch"
          className={isLoggedIn ? classes.link : classes.linkDisabled}
        >
          {t('dispatch')}
          {totals.some((item) => item.amount > 0) && isLoggedIn ? (
            <span className={classes.notificationBubble}></span>
          ) : null}
        </Link>

        <Link
          href="/balance"
          className={isLoggedIn ? classes.link : classes.linkDisabled}
        >
          {t('balance')}
          {balanceData.topupForOrder || balanceData.topupForShipment ? (
            <span className={classes.notificationBubble}></span>
          ) : null}
        </Link>

        <Link href="/offers" className={classes.link}>
          {t('offers')}
        </Link>
      </div>

      {!listShirnk ? (
        <div className={classes.headerButtons}>
          {!isLoggedIn ? (
            <>
              <Link href="/login">
                <div className={classes.buttonContainer}>
                  <Button
                    variant={buttonVariant}
                    color="default"
                    className={`${classes.button} ${classes.cart}`}
                    onClick={() => {}}
                    size={'small'}
                  >
                    {t('cart')}
                  </Button>
                </div>
              </Link>
              <Link href="/register">
                <Button
                  variant={buttonVariant}
                  color="default"
                  className={`${classes.button} ${classes.register}`}
                  size={'small'}
                >
                  {t('register')}
                </Button>
              </Link>
              <Link href="/login">
                <Button
                  variant={buttonVariant}
                  color="primary"
                  className={`${classes.button} ${classes.login}`}
                  size={'small'}
                >
                  {t('login')}
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Link href="/cart">
                <div className={classes.buttonContainer}>
                  <Button
                    variant={buttonVariant}
                    color="default"
                    className={`${classes.button} ${classes.cart}`}
                    onClick={() => {}}
                    size={'small'}
                  >
                    {t('cart')}
                  </Button>
                  {cartNotification ? (
                    <span className={classes.notificationBubbleButton}></span>
                  ) : null}
                </div>
              </Link>
              <Link href="/profile">
                <Button
                  variant={buttonVariant}
                  color="default"
                  className={`${classes.button} ${classes.register}`}
                  size={'small'}
                >
                  {customerCode || t('profile')}
                </Button>
              </Link>
              <Button
                variant={buttonVariant}
                color="primary"
                className={`${classes.button} ${classes.logout}`}
                onClick={onLogout}
                size={'small'}
              >
                {t('logout')}
              </Button>
            </>
          )}
        </div>
      ) : (
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.iconButton}
            disableRipple
          >
            <PersonIcon fontSize="medium" />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: '200px',
                width: 'auto',
                marginTop: '15px',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                backdropFilter: 'blur(5px)',
              },
            }}
          >
            {isLoggedIn ? (
              <div>
                <MenuItem onClick={handleClose}>
                  <Link href="/cart">
                    <div className={classes.buttonContainer}>
                      <Button
                        variant={buttonVariant}
                        color="default"
                        className={`${classes.button} ${classes.cart}`}
                        onClick={() => {}}
                        size={'small'}
                      >
                        {t('cart')}
                      </Button>
                      {cartNotification ? (
                        <span
                          className={classes.notificationBubbleButton}
                        ></span>
                      ) : null}
                    </div>
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <Link href="/profile">
                    <Button
                      variant={buttonVariant}
                      color="default"
                      className={`${classes.button} ${classes.register}`}
                      size={'small'}
                    >
                      {customerCode || t('profile')}
                    </Button>
                  </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                  <Button
                    variant={buttonVariant}
                    color="primary"
                    className={`${classes.button} ${classes.logout}`}
                    onClick={onLogout}
                    size={'small'}
                  >
                    {t('logout')}
                  </Button>
                </MenuItem>
              </div>
            ) : (
              <div>
                <MenuItem onClick={handleClose}>
                  <Link href="/register">
                    <Button
                      variant={buttonVariant}
                      color="default"
                      className={`${classes.button} ${classes.register}`}
                      size={'small'}
                    >
                      {t('register')}
                    </Button>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link href="/login">
                    <Button
                      variant={buttonVariant}
                      color="primary"
                      className={`${classes.button} ${classes.login}`}
                      size={'small'}
                    >
                      {t('login')}
                    </Button>
                  </Link>
                </MenuItem>
              </div>
            )}
          </Menu>
        </div>
      )}
    </>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;
  return {
    header: {
      backgroundColor: colors.header,
      minHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '50px',
      },
    },
    headerList: {
      marginTop: 0,
      fontSize: '14px',
      whiteSpace: 'pre',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    headerButtons: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    button: {
      textTransform: 'none',
      fontSize: '14px',
      minWidth: '79px',
      height: '30px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    register: {
      ...theme.button.transparent,
    },
    iconButton: {
      backgroundColor: 'rgba(0, 122, 255, 0.15)',
      padding: '5px',
      '& > *': {
        color: 'rgba(0, 122, 255, 1)',
      },
    },
    login: {
      background: '#007AFF',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        background: 'rgba(0, 122, 255, 0.15)',
        color: '#007AFF',
        boxShadow: 'none',
      },
    },
    logout: {
      borderRadius: '10px',
    },
    cart: {
      background: '#000000',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        background: '#000000bb',
      },
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-between',
    },
    link: {
      textDecoration: 'none',
      color: colors.navLink.fontColor,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '&:hover': {
        color: colors.navLink.fontColor,
        borderBottom: '1px solid white',
        boxShadow: 'none',
      },
    },
    linkDisabled: {
      textDecoration: 'none',
      color: colors.navLink.disabled,
      pointerEvents: 'none',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '&:hover': {
        color: colors.navLink.disabled,
        borderBottom: '1px solid white',
      },
    },
    notificationBubble: {
      display: 'inline-block',
      width: '8px',
      height: '8px',
      backgroundColor: '#FF9500',
      borderRadius: '50%',
      marginLeft: '5px',
    },
    notificationBubbleButton: {
      position: 'absolute',
      top: '-4px', // Adjust as needed
      right: '4px', // Adjust as needed
      width: '14px',
      height: '14px',
      backgroundColor: '#FF3B30',
      borderRadius: '50%',
      [theme.breakpoints.down('sm')]: {
        top: '1px',
      },
    },
    buttonContainer: {
      position: 'relative',
      display: 'inline-block', // To not take full width and mess up layout
    },
  };
};

export default withWidth()(withStyles(styles)(NavBar));
