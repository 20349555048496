import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  withStyles,
  Button,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';
import Slider from 'react-slick';
import {
  Page,
  SearchBlock,
  CardComponent,
  TimelineComponent,
  SlickSlider,
  HugeCard,
  NewsCard,
  ContactSection,
} from '../components';

import { newsList } from '../api/news-api';

const NewsPortal = ({ news, classes }) => {
  const { t, i18n } = useTranslation();
  const [newsCardsData, setNewsCardsData] = useState([]);
  const [visibleCards, setVisibleCards] = useState(6);
  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3);
  };

  useEffect(() => {
    const fetchNewsList = async () => {
      try {
        const data = await newsList({ lang: i18n.language, fresh: false });
        setNewsCardsData(data);
      } catch (error) {
        console.error('Failed to fetch news list', error);
      }
    };

    fetchNewsList();
  }, [i18n.language]);

  return (
    <Page>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
              {t('news_section')}
            </Typography>
            <Typography
              variant="h5"
              classes={{ h5: classes.sectionSubHeading }}
            >
              {t('news_title')}
            </Typography>
          </Grid>
          {newsCardsData.slice(0, visibleCards).map((card, index) => (
            <Grid item md={4} sm={6} xs={12}>
              <NewsCard
                grey
                style={{ background: '#F5F5F7' }}
                key={index}
                id={card.id}
                date={card.date.split('T')[0]}
                content={card.text}
                subTitle={card.title}
              />
            </Grid>
          ))}
          {visibleCards < newsCardsData.length && (
            <Button className={classes.loadMoreButton} onClick={handleLoadMore}>
              Load More
            </Button>
          )}
          <ContactSection />
        </Grid>
      </div>
    </Page>
  );
};

const styles = (theme) => {
  const paddings = {
    [theme.breakpoints.up('md')]: {
      marginLeft: '15%',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  };
  return {
    sectionGrey: {
      backgroundColor: '#F2F2F7',
    },

    sectionHeading: {
      marginTop: '60px',

      fontSize: '2rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    SlickSlider: {
      display: 'block',
      width: '90%',
      height: 'auto !important' /* Set height to auto */,

      [theme.breakpoints.down('md')]: {
        width: '95%',
        display: 'inline-block !important',
      },
      [theme.breakpoints.up('lg')]: {
        width: '85%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%',
      },
    },
    loadMoreButton: {
      margin: '30px auto',
      fontSize: '16px',
      color: '#007AFF',
      textTransform: 'capitalize',
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: '#ffffff',
      },
    },
    sectionSubHeading: {
      marginBottom: '50px',
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    heroText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    heroHeading: {
      ...paddings,
      fontSize: '2.3rem',
      fontWeight: 700,
      width: '80%',
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        marginTop: '30px',
        width: '75%',
        textAlign: 'center',
      },
    },
    heroSubHeading: {
      ...paddings,
      letterSpacing: '0px',
      color: '#000',
      marginTop: '16px',
      fontSize: '22px',
      fontWeight: 400,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '65%',
      },
    },
    heroButton: {
      ...paddings,
      minWidth: '135px',
      marginTop: '16px',
      fontSize: '21px',
      textTransform: 'capitalize',
      background: '#007AFF',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        background: '#007AFF15',
        color: '#007AFF',
      },
    },
    cards: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '2rem',
      marginRight: '1.5rem',
      [theme.breakpoints.down('md')]: {
        marginRight: '0px',
      },
    },

    slideContainer: {
      background: '#ffffff',
    },
    aboutText: {
      margin: '1rem auto',
      width: '50%',
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    hugeCardsContainer: {
      margin: '2rem auto',
      display: 'flex',
      justifyContent: 'center',
    },
    hugeCardNarrow: {
      margin: '10px 0',
      justifyContent: 'center',
      display: 'flex',
      [theme.breakpoints.up('xl')]: {
        flexBasis: '25%',
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: '32%',
      },
      [theme.breakpoints.down('lg')]: {
        flexBasis: '41%',
      },
      [theme.breakpoints.down('md')]: {
        flexBasis: '50%',
      },
    },
    hugeCardWide: {
      margin: '10px 0',
      justifyContent: 'center',
      display: 'flex',
    },
    contactData: {
      ...paddings,
      letterSpacing: '0px',
      color: '#000',
      marginTop: '16px',
      fontSize: '22px',
      fontWeight: 500,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '65%',
      },
    },
  };
};
export default withStyles(styles)(NewsPortal);
