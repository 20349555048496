import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Page,
  ShipmentsTable,
  ShipmentsTotalTable,
  ShipmentDateDialog,
  EditDeliveryDialog,
} from '../components';
import * as ordersApi from '../api/order-api';
import * as shipmentApi from '../api/shipment-api';
import * as deliveryApi from '../api/delivery-api';
import * as dictionaryApi from '../api/dictionary-api';

import { getCookie } from '../utils/cookies';
import { Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const ShipmentsPage = ({ classes }) => {
  const { t } = useTranslation();
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [totals, setTotals] = useState([]);
  const [shipDateItem, setShipDateItem] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [boxItems, setBoxItems] = useState([]);
  const [delivery, setDelivery] = useState(null);
  const [deliveryData, setDeliveryData] = useState({
    tariff: '',
    active: true,
    countryCode: '',
    city: '',
    province: '',
    zipCode: '',
    street: '',
    building: '',
    flat: '',
    addInfo: '',
    carrierId: 0,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [allDeliveries, setAllDeliveries] = useState([]);
  const [editDeliveryError, setEditDeliveryError] = useState('');
  const [anchor, setAnchor] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [deliveryTariffs, setDeliveryTariffs] = useState([]);
  const [isAddDeliveryDialogOpen, setAddDeliveryDialogOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) fetchTotals();
    getCountryList();
  }, []);

  useEffect(() => {
    if (isLoggedIn)
      if (delivery) {
        fetchBoxes(delivery);
        fetchDeliveryData(delivery);
        getDeliveryTariffList();
      }
  }, [delivery]);

  async function fetchTotals() {
    try {
      const result = await ordersApi.readyTotals(getCookie('currency'));
      if (result.length) {
        setDelivery(result[0].delivery);
        setTotals(result);
      } else {
        setDelivery(null);
        setTotals([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  async function getDeliveryTariffList() {
    const result = await deliveryApi.deliveries(true, false);
    setDeliveryTariffs(
      result.map((row) => ({
        title: row['text'],
        value: row['id'],
        local: row['tariffLocal'],
        tariff: row['tariff'],
      }))
    );
  }

  async function fetchDeliveryData(delivery) {
    const result = await deliveryApi.deliveryDetails(delivery);
    const result_all = await deliveryApi.deliveryDetails();
    const deliveryIndex = result.findIndex((obj) => obj.sortingId === delivery);
    const source = result[deliveryIndex];

    let target = { ...deliveryData };
    let result_ = { ...target };
    Object.keys(source).forEach((key) => {
      if (key in target) {
        result_[key] = source[key];
      }
    });
    result_.sortingId = result[deliveryIndex].sortingId;
    result_.type = result[deliveryIndex].type;
    setDeliveryData(result_);
    setAllDeliveries(result_all);
  }
  async function fetchBoxes(delivery) {
    const result = await ordersApi.readyBoxes(delivery, getCookie('currency'));
    setBoxes(result);
  }

  async function fetchBoxItems(boxId) {
    const result = await ordersApi.readyItems(boxId, getCookie('currency'));
    setBoxItems([
      ...boxItems.filter((item) => item.boxId !== boxId),
      ...result,
    ]);
  }

  function handleDialogOpen(value) {
    setDialogOpen(value);
  }

  function handleRowUnfold(boxId) {
    fetchBoxItems(boxId);
  }

  function handleDeliverySelect(delivery) {
    setDelivery(delivery);
  }

  function handleAddDeliveryDialogClose(initialId) {
    setAddDeliveryDialogOpen(false);
  }

  function handleAddDeliveryDialogOpen() {
    setAddDeliveryDialogOpen(true);
  }

  function handleEditDelivery() {
    setDelivery(delivery); // tackle weird behaviour of the table
    setAddDeliveryDialogOpen(true);
  }

  async function handleChangeDelivery(data) {
    const { sortingId } = data;
    setDelivery(sortingId);
    delete data.sortingId;
    delete data.type;
    try {
      await deliveryApi.modifyDelivery(sortingId, data);
      setAddDeliveryDialogOpen(false);
      fetchTotals();
    } catch (err) {
      setEditDeliveryError(err.message);
      console.log(err);
    }
  }

  async function handleShipmentDateClick(totalsItem, event) {
    setShipDateItem(totalsItem);
    setAnchor(event.currentTarget);
  }

  async function handleShipmentDateChange(date, enabled) {
    try {
      const result = await shipmentApi.order(shipDateItem.id, {
        enabled,
        delivery: shipDateItem.delivery,
        schedule: enabled ? date : undefined,
      });
      setTotals(
        totals.map((item) =>
          item.delivery !== shipDateItem.delivery
            ? item
            : {
                ...shipDateItem,
                shipmentDate: enabled ? result[0].schedule : null,
                shipmentId: enabled ? result[0].id : null,
              }
        )
      );
    } catch {
      alert(t('cannot_update_shipment_date'));
      setDialogOpen(false);
    }
    setDialogOpen(false);
    setShipDateItem(null);
  }

  function handleDialogClose() {
    setShipDateItem(null);
    setDialogOpen(false);
  }
  return (
    <Page>
      {dialogOpen && <div className={classes.overlay} />}
      <div>
        <Helmet>
          <title>
            Delivery - AllCarParts - Your orders are ready for dispatch. Tell us
            when to deliver them.{' '}
          </title>
          <meta
            name="description"
            content="See which of your orders are ready to be shipped and just choose a delivery date"
          ></meta>
        </Helmet>
      </div>

      {!isLoggedIn && <Redirect to="/search" />}
      <div className={classes.totalTableContainer}>
        <ShipmentsTotalTable
          data={totals}
          descriptions
          delivery={delivery}
          onSelect={handleDeliverySelect}
          onShipmentDateClick={handleShipmentDateClick}
          onEditDelivery={handleEditDelivery}
          deliveryData={deliveryData}
        />
      </div>
      <div className={classes.boxesTableContainer}>
        <ShipmentsTable
          boxes={boxes}
          boxItems={boxItems}
          onUnfold={handleRowUnfold}
        />
      </div>
      {!!shipDateItem && (
        <ShipmentDateDialog
          defaultDate={shipDateItem.shipmentDate}
          onSubmit={handleShipmentDateChange}
          onClose={handleDialogClose}
          onOpen={handleDialogOpen}
          anchor={anchor}
        />
      )}
      {isAddDeliveryDialogOpen && (
        <EditDeliveryDialog
          error={editDeliveryError}
          onClose={handleAddDeliveryDialogClose}
          onSubmit={handleChangeDelivery}
          deliveryTariffList={deliveryTariffs}
          data={deliveryData}
          countryList={countryList}
        />
      )}
    </Page>
  );
};

const styles = (theme) => {
  return {
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      transition: '0.5s ease-in-out',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: ' blur(10px)',
      'z-index': '1000',
    },
    totalTableContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    boxesTableContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: 'calc(100vh - 275px)',
      width: '100%',
      flexGrow: 1,
      margin: '0 auto',
      overflowX: 'auto',
    },
  };
};

export default withStyles(styles)(ShipmentsPage);
