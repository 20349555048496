import React from 'react';
import { withStyles } from '@material-ui/core';
import { MenuItem, TextField as MuiSelect } from '@material-ui/core';

const currencies = [
  { title: 'USD', value: 'USD' },
  { title: 'AED', value: 'AED' },
];

const SelectCurrency = ({
  classes,
  className,
  inputClasses,
  value,
  label,
  textColor,
  labelColor,
  variant,
  onChange,
}) => {
  function handleChange(event) {
    onChange(event.target.value);
  }

  return (
    <MuiSelect
      select
      label={label}
      value={value}
      variant={variant}
      onChange={handleChange}
      InputProps={{
        style: {
          fontSize: 'small',
          color: { textColor },
        },
      }} // font size of input text
      InputLabelProps={{
        style: {
          fontSize: 'medium',
          color: { labelColor },
        },
      }} // font size of label text
      classes={{
        select: classes.root,
      }}
    >
      {currencies.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

const styles = (theme) => ({
  input: {
    backgroundColor: '#fff',
  },
  root: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  inputMarginDense: {
    paddingLeft: theme.spacing(1) / 2,
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
});

export default withStyles(styles)(SelectCurrency);
