import React, { useEffect, useState } from 'react';
import * as deliveryApi from '../api/delivery-api';
import { isEnterKey } from '../utils/keyEvents';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Chip,
  withStyles,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import delivery_icon from '../assets/image/delivery_icon.png';
import carrier_icon from '../assets/image/carrier_icon.png';

const EditDeliveryDialog = ({
  classes,
  open,
  error,
  countryList,
  onClose,
  onSubmit,
  data,
}) => {
  const { t } = useTranslation();
  const [carriers, setCarriers] = useState([]);
  const [incorrectWeight, setIncorrectWeight] = useState(false);
  const [deliveryTariffList, setDeliveryTariffList] = useState([]);
  const [days, setDays] = useState({
    onrequest: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [formError, setFormError] = useState('');
  const [isLocal, setIsLocal] = useState(false);

  const [newDelivery, setNewDelivery] = useState(data);
  const [invalid, setInvalid] = useState('');
  async function getDeliveryTariffList() {
    const result = await deliveryApi.deliveries(false, false);
    setDeliveryTariffList(
      result.map((row) => ({
        title: row['text'],
        value: row['id'],
        local: row['tariffLocal'],
        tariff: row['tariff'],
      }))
    );
  }
  useEffect(() => {
    fetchCarriers();
    getDeliveryTariffList();
    Object.keys(data).forEach((key) => {
      if (key in days) {
        days[key] = data[key];
      }
    });
  }, []);

  function handleEnterPress(event) {
    if (isEnterKey(event)) {
      event.stopPropagation();
      handleSubmit();
    }
  }

  async function fetchCarriers() {
    const result = await deliveryApi.carriers();
    setCarriers(result);
  }

  function handleClose() {
    onClose(data.sortingId);
  }

  function handleSubmit() {
    if (Object.keys(newDelivery).some((key) => !newDelivery[key])) {
      if (isNumberAndGreaterThan50(newDelivery.addInfo)) {
        onSubmit(newDelivery);
        setNewDelivery([]);
      } else {
        setIncorrectWeight(true);
        return;
      }
    } else alert('Please fill all the fields');
  }

  const handleFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setNewDelivery((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'tariff') {
      const selectedItem = deliveryTariffList.find(
        (item) => item.value === value
      );
      if (selectedItem && selectedItem.local) {
        setIsLocal(true);
      } else {
        setIsLocal(false);
      }
    }
  };

  const handleDateChange = (event) => {
    let updatedDays = { ...days, [event.target.name]: event.target.checked };

    if (event.target.name === 'onrequest' && event.target.checked) {
      updatedDays = {
        onrequest: true,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      };
    }

    setDays(updatedDays);

    const { onrequest, ...daysWithoutOnrequest } = updatedDays;
    setNewDelivery({ ...newDelivery, ...daysWithoutOnrequest });
  };

  const handleActiveChange = (event) => {
    setNewDelivery({
      ...newDelivery,
      [event.target.name]: event.target.checked,
    });
  };

  const isNumberAndGreaterThan50 = (str) => {
    const num = parseFloat(str);
    return !isNaN(num) && num >= 50;
  };
  const submitError = error || formError;
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      onKeyPress={handleEnterPress}
    >
      <DialogContent className={classes.dialog}>
        {!!submitError && (
          <Chip
            className={classes.errorChip}
            color="secondary"
            icon={<ErrorIcon />}
            label={submitError}
          />
        )}
        <form>
          <Grid item container>
            <Grid item md={12}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('edit_delivery_heading')}
              </Typography>
            </Grid>
            <Grid item md={12} style={{ marginTop: '10px' }}>
              <Typography variant="p" className={classes.textLabel}>
                {t('select_destination')}
              </Typography>
              <Grid item>
                <TextField
                  classes={{ root: classes.selectField }}
                  items={deliveryTariffList}
                  name="tariff"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={delivery_icon} className={classes.icon} /> |
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  select
                  value={newDelivery.tariff}
                  onChange={handleFieldChange}
                  icon={delivery_icon}
                >
                  {deliveryTariffList.map((item) => (
                    <MenuItem key={item.value} value={item.tariff}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item container style={{ marginTop: '30px' }}>
              <Grid item md={6} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_country')}
                </Typography>
                <TextField
                  name="countryCode"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  select
                  placeholder={t('not_selected')}
                  value={newDelivery.countryCode}
                  onChange={handleFieldChange}
                  required={true}
                  error={invalid === 'countryCode'}
                >
                  {countryList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_zip')}
                </Typography>
                <TextField
                  name="zipCode"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="05000"
                  fullWidth
                  value={newDelivery.zipCode}
                  onChange={handleFieldChange}
                  error={invalid === 'zipCode'}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_state')}
                </Typography>
                <TextField
                  name="province"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder={t('california')}
                  fullWidth
                  value={newDelivery.province}
                  onChange={handleFieldChange}
                  error={invalid === 'state'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_city')}
                </Typography>
                <TextField
                  name="city"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder={t('san_francisco')}
                  fullWidth
                  value={newDelivery.city}
                  onChange={handleFieldChange}
                  required={true}
                  error={invalid === 'city'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_street')}
                </Typography>
                <TextField
                  name="street"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="Main st."
                  fullWidth
                  value={newDelivery.street}
                  onChange={handleFieldChange}
                  error={invalid === 'street'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_building')}
                </Typography>
                <TextField
                  name="building"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  placeholder="54"
                  fullWidth
                  value={newDelivery.building}
                  onChange={handleFieldChange}
                  error={invalid === 'building'}
                />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('user_flat')}
                </Typography>
                <TextField
                  name="flat"
                  autoComplete=""
                  margin="normal"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  placeholder="32A"
                  value={newDelivery.flat}
                  onChange={handleFieldChange}
                  error={invalid === 'flat'}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.textLabel}>
                  {t('delivery_days')}
                </Typography>
                <Grid item>
                  {Object.keys(days).map((day) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={days[day]}
                          onChange={handleDateChange}
                          name={day}
                          color="primary"
                          disabled={day !== 'onrequest' && days.onrequest}
                        />
                      }
                      label={day.charAt(0).toUpperCase() + day.slice(1)}
                      key={day}
                    />
                  ))}
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('choose_carrier')}
                  </Typography>
                  <Typography
                    variant="p"
                    className={classes.textLabel}
                    style={{ color: '#FF6961' }}
                  >
                    {!isLocal && data.type != 'LOCL'
                      ? ' Only for Local deliveries'
                      : ''}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    classes={{ root: classes.selectField }}
                    items={carriers}
                    InputProps={{
                      classes: {
                        disabled: classes.disabledField,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={carrier_icon} className={classes.icon} /> |
                        </InputAdornment>
                      ),
                    }}
                    select
                    fullWidth
                    name="carrierId"
                    disabled={!isLocal && data.type != 'LOCL'}
                    value={newDelivery.carrierId}
                    onChange={handleFieldChange}
                    icon={delivery_icon}
                  >
                    {carriers.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Trans i18nKey="no_carrier">
                    Нет перевозчика в списке?
                    <Link
                      to="/contacts"
                      style={{ textDecoration: 'none', color: '#007AFF' }}
                    >
                      Напишите нам
                    </Link>
                  </Trans>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '30px' }}>
                <Typography variant="p" className={classes.textLabel}>
                  {t('min_weight')}
                </Typography>

                <Grid item xs={6}>
                  <TextField
                    name="addInfo"
                    className={classes.textField}
                    variant="outlined"
                    margin="normal"
                    placeholder="50"
                    value={newDelivery.addInfo}
                    onChange={handleFieldChange}
                    error={invalid === 'currencyCode'}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="p"
                    className={classes.textLabel}
                    style={{ color: '#FF6961' }}
                  >
                    {incorrectWeight ? 'Should be >=50' : ''}
                  </Typography>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="active"
                      checked={newDelivery.active}
                      onChange={handleActiveChange}
                      label={t('destination_active')}
                      color="primary"
                    />
                  }
                  label={t('destination_active')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <span>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={handleClose}
              >
                Отмена
              </Button>

              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Подтвердить
              </Button>
            </span>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

EditDeliveryDialog.defaultProps = {
  open: true,
};

const styles = (theme) => {
  const colors = theme.palette.custom.signUpDialog;

  return {
    formContainer: {
      padding: '30px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '715px',
      margin: '0 auto 0 30px',
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '370px',
      },
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    searchHeading: {
      maxWidth: '350px',
      fontWeight: '500',
      fontSize: '22px',
      paddingBottom: '12px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    dialog: {
      backgroundColor: '#F2F2F7',
    },
    button: {
      ...theme.button.filled,
      minWidth: '85px',
      marginRight: '10px',
      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
      '&.Mui-disabled': {
        ...theme.button.transparent,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
    },
    textField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
        backgroundColor: '#ffffff',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    addressField: {
      '& > *': {
        borderRadius: '10px',
        height: '64px',
        width: 'clamp(280px, 100%, 600px)',
        backgroundColor: '#ffffff',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    textLabel: {
      fontSize: '14px',
      marginBottom: '5px',
      marginTop: '30px',
    },
    selectField: {
      borderRadius: '10px',
      backgroundColor: '#fff',
      width: 'clamp(265px, 100%, 900px)',
      height: '32px',
    },
    disabledField: {
      opacity: '0.3',
    },
    nameContainer: {
      display: 'flex',
    },
    nameTextField: {
      marginTop: 0,
      backgroundColor: colors.textField.background,
      '&:first-child': {
        marginRight: theme.spacing(1),
      },
    },

    errorChip: {
      color: colors.errorChip.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
    icon: {
      width: '14px',
      marginTop: '3px',
      marginRight: '5px',
      marginLeft: '10px',
    },
  };
};

export default withStyles(styles)(EditDeliveryDialog);
