import React, { useMemo, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import ReactStars from 'react-rating-stars-component';

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Button,
} from '@material-ui/core';

import QuantityField from './QuantityField';

const RowCheckbox = ({ id, groupId, checked, onChange, className }) => {
  function handleOnChange() {
    onChange && onChange(id, groupId);
  }

  return (
    <Checkbox
      className={className}
      disableRipple
      checked={checked}
      onChange={handleOnChange}
    />
  );
};

const GroupSearchTable = ({
  classes,
  groupId,
  data,
  confirmedData,
  showReference,
  onDataChange,
  isLoggedIn,
  onSelect,
  onSelectAll,
  noSplit,
  groupMethod,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandBrands, setExpandBrands] = useState([]);
  const [bestNumber, setBestNumber] = useState(0);
  const [brandGroups, setBrandGroups] = useState([]);

  function handleQuantityChange(id, quantity) {
    const newData = data.map((value) =>
      value.id !== id
        ? value
        : {
            ...value,
            quantity,
            confirmed: true,
          }
    );
    onDataChange && onDataChange(groupId, newData); // pass the groupId along with the new data
  }

  function handleSelectAll(event) {
    onSelectAll && onSelectAll(event.target.checked, groupId);
  }

  function handleExpandClick(event, value) {
    if (expandBrands.includes(value)) {
      let index = expandBrands.indexOf(value);
      setExpandBrands((prevExpandBrands) =>
        prevExpandBrands.filter((_, i) => i !== index)
      );
    } else {
      setExpandBrands((prevExpandBrands) => [...prevExpandBrands, value]);
    }
  }

  useEffect(() => {
    setBrandGroups(groupMethod(data, 'brand'));
  }, [data]);

  const isAllConfirmed =
    data.length > 0 && confirmedData
      ? confirmedData.length === data.length
      : false;

  const currency = data.length ? data[0].currency : '';
  return (
    <div className={`${classes.container}`}>
      <TableContainer
        component={Paper}
        variant="rounded"
        className={classes.tableContainer}
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  className={classes.checkbox}
                  disableRipple
                  checked={isAllConfirmed}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>
                <div>{t('part_number')}</div>
                <div>{t('substituted')}</div>
              </TableCell>
              <TableCell>{t('brand')}</TableCell>
              <TableCell>{t('description')}</TableCell>
              <TableCell>{t('days')}</TableCell>
              <TableCell>{t('price_with_currency', { currency })}</TableCell>
              {isLoggedIn && <TableCell>{t('quantity')}</TableCell>}
              <TableCell>{t('available')}</TableCell>
              <TableCell className={classes.ratingCell}>Rating</TableCell>
              <TableCell className={classes.weightCell}>
                <div>{t('weight_kg')}</div>
                <div>{t('volume_kg')}</div>
              </TableCell>
              {showReference && <TableCell>{t('reference')}</TableCell>}
              <TableCell>{t('booking')}</TableCell>
              <TableCell>{t('comment')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(brandGroups).map(([brand, items]) => {
              const displayedItems = expandBrands.includes(brand)
                ? items
                : items.filter((item) => item.groupTops === 1);
              return (
                <>
                  {displayedItems.map((value, index) => (
                    <TableRow
                      key={value.id}
                      className={
                        index % 2 !== 0
                          ? classes.evenRow
                          : index === 0
                          ? classes.oddRow2
                          : classes.oddRow
                      }
                    >
                      <TableCell padding="checkbox">
                        <RowCheckbox
                          id={value.id}
                          groupId={groupId}
                          checked={value.confirmed}
                          onChange={onSelect}
                        />
                      </TableCell>
                      <TableCell>
                        <div>{value.partNumber}</div>
                        <div className={classes.warnValue}>
                          {value.inpPartNumber !== value.partNumber
                            ? value.inpPartNumber
                            : ''}
                        </div>
                      </TableCell>
                      <TableCell>{value.brand}</TableCell>
                      <TableCell>{value.description}</TableCell>
                      <TableCell>{value.days}</TableCell>
                      <TableCell>{value.price}</TableCell>
                      {isLoggedIn && (
                        <TableCell>
                          <QuantityField
                            className={classes.quantityField}
                            id={value.id}
                            quantity={value.quantity}
                            onChange={handleQuantityChange}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        {value.available
                          ? value.available
                          : value.price
                          ? t('for_order').toUpperCase()
                          : t('n_a')}
                      </TableCell>
                      <TableCell>
                        <ReactStars
                          count={4}
                          size={20}
                          activeColor="#ffd700"
                          value={value.rating / 25}
                          max={5}
                          edit={false}
                          isHalf={true}
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.weightValue}>
                          {value.weightKg}
                        </div>
                        <div>{value.volumeKg}</div>
                      </TableCell>
                      {showReference && (
                        <TableCell>{value.yourReference}</TableCell>
                      )}
                      <TableCell>{value.booking}</TableCell>
                      <TableCell>
                        <div className={classes.warnValue}>
                          {' '}
                          {value.errDesc}{' '}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}

                  {items.filter((item) => item.groupTops === 1).length <
                  items.length ? (
                    <TableRow>
                      <TableCell colSpan={11}>
                        <Button
                          onClick={(event) => handleExpandClick(event, brand)}
                          className={classes.expandButton}
                        >
                          {expandBrands.includes(brand)
                            ? t('collapse')
                            : `${t('load_all_items')} (${
                                items.length - displayedItems.length
                              })`}
                        </Button>
                        <hr
                          style={{
                            borderTop: '0.5px solid rgba(25, 25, 25, 0.25)',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.length > 1 && (
                      <TableRow>
                        <TableCell colSpan={11}>
                          <hr
                            style={{
                              borderTop: '0.5px solid rgba(25, 25, 25, 0.25)',
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  return {
    container: {
      flex: '1 0 0px',
      margin: '0 auto',
      overflow: 'auto',
      width: '100%',
    },
    tableContainer: {
      margin: '0 auto',
      boxShadow: 'none',
      display: 'flex',
      maxHeight: '100%',
      overflowY: 'auto',
      flexGrow: 1,
      border: '1px solid #00000040',
      borderRadius: '10px',
    },
    evenRow: {
      backgroundColor: '#FBFBFB',
      borderTop: '1px solid #00000040',
    },
    oddRow: {
      backgroundColor: '#ffffff',
      borderTop: '1px solid #00000040',
    },
    oddRow2: {
      backgroundColor: '#ffffff',
      borderTop: '2px solid #ffffff',
    },
    weightCell: {
      whiteSpace: 'nowrap',
    },
    weightValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`,
    },
    pointValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`,
    },
    ratingCell: {
      textAlign: 'left',
      padding: 20,
    },
    rating: {
      display: 'flex',
    },
    ratingIcon: {
      width: 20,
      height: 20,
    },
    ratingIconButton: {
      padding: 1,
    },
    quantityField: {
      alignItems: 'center',
    },
    warnValue: {
      color: 'red',
    },
    checkbox: {
      padding: 0,
    },
    expandButton: {
      color: '#007AFF',
      fontWeight: '400',
      textTransform: 'none',
      lineHeight: 1,
      fontSize: 12,
    },
  };
};

export default withStyles(styles)(GroupSearchTable);
