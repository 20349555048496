import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MenuItem } from '@material-ui/core';

const settings = {
  className: 'car-slider',
  slidesToShow: 13,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  arrows: false,
  dots: false,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const SlickSlider = ({ items }) => {
  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <div lassName="slide" key={`${index}`}>
          <img src={item} alt={`${index}`} />
        </div>
      ))}
    </Slider>
  );

  //    return <div><img src={items[0]} alt="brand" /></div>
};

export default SlickSlider;
