import React, { useState, useEffect } from 'react';
import * as basketApi from '../api/basket-api';
import * as balanceApi from '../api/balance-api';
import * as shipmentApi from '../api/shipment-api';
import * as ordersApi from '../api/order-api';
import { newsList } from '../api/news-api';
import { useTranslation } from 'react-i18next';

import { getCookie } from '../utils/cookies';
export const NotificationContext = React.createContext(null);

export const useNotificationContext = () => {
  return React.useContext(NotificationContext);
};

export const NotificationContextProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [cartNotification, setCartNotification] = useState(false);
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [cartData, setCartData] = useState([]);
  const [balanceData, setBalanceData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [totals, setTotals] = useState([]);
  const [newsCardsData, setNewsCardsData] = useState([]);
  const [newsCardsDataAll, setNewsCardsDataAll] = useState([]);

  useEffect(() => {
    fetchNewsList();
    if (isLoggedIn) {
      fetchCartData();
      fetchBalanceData();
      fetchTotals();
      fetchOrderData();
    }
  }, []);

  const fetchNewsList = async () => {
    try {
      const data = await newsList({ lang: i18n.language, fresh: true });
      const dataAll = await newsList({ lang: i18n.language, fresh: false });
      setNewsCardsDataAll(dataAll);
      setNewsCardsData(data);
    } catch (error) {
      console.error('Failed to fetch news list', error);
    }
  };

  async function fetchCartData() {
    const result = await basketApi.select();
    setCartData(result);
  }
  async function fetchBalanceData() {
    const result = await balanceApi.totals();
    setBalanceData(result);
  }
  async function fetchTotals() {
    try {
      const result = await ordersApi.readyTotals(getCookie('currency'));
      if (result.length) {
        setTotals(result);
      } else {
        setTotals([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchOrderData(query) {
    const result = await ordersApi.states(query);
    setOrderData(result);
  }
  useEffect(() => {
    cartData.length ? setCartNotification(true) : setCartNotification(false);
  }, [cartData]);

  const value = {
    cartNotification,
    setCartNotification,
    cartData,
    setCartData,
    balanceData,
    setBalanceData,
    orderData,
    setOrderData,
    totals,
    setTotals,
    newsCardsData,
    newsCardsDataAll,
    setNewsCardsDataAll,
    setNewsCardsData,
    fetchCartData,
    fetchBalanceData,
    fetchOrderData,
    fetchTotals,
    fetchNewsList,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
