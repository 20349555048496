import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { useTranslation } from 'react-i18next';

const PageTabs = ({ props, classes, width, isLoggedIn }) => {
  const [currentTab, setCurrentTab] = useState(window.location.pathname);

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const isScrollable = isWidthDown('sm', width);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant={isScrollable ? 'scrollable' : 'standard'}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Tab
          className={classes.tab}
          classes={{ selected: classes.tabSelected }}
          value={'/search'}
          label={t('search')}
          to="/search"
          component={Link}
        />
        <Tab
          className={classes.tab}
          classes={{ selected: classes.tabSelected }}
          value={'/basket'}
          label={t('cart')}
          to="/basket"
          component={Link}
        />
        <Tab
          className={classes.tab}
          classes={{ selected: classes.tabSelected }}
          disabled={!isLoggedIn}
          value={'/orders'}
          label={t('orders')}
          to="/orders"
          component={Link}
        />
        <Tab
          className={classes.tab}
          classes={{ selected: classes.tabSelected }}
          disabled={!isLoggedIn}
          value={'/dispatch'}
          label={t('dispatch')}
          to="/dispatch"
          component={Link}
        />
        <Tab
          className={classes.tab}
          classes={{ selected: classes.tabSelected }}
          disabled={!isLoggedIn}
          value={'/balance'}
          label={t('balance')}
          to="/balance"
          component={Link}
        />
        <Tab
          className={classes.tab}
          classes={{ selected: classes.tabSelected }}
          value={'/offers'}
          label={t('offers')}
          to="/offers"
          component={Link}
        />
      </Tabs>
      <Button
        variant="contained"
        size="small"
        className={classes.button}
        href={`mailto: ${window.COMPANY_EMAIL}`}
      >
        {t('contact_us')}
      </Button>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: theme.palette.custom.pageTabs.tab,
    color: '#fff',
    position: 'relative',
    justifyContent: 'center',
    marginTop: '2px',
    [theme.breakpoints.down('sm')]: {},
  },
  button: {
    position: 'absolute',
    top: '9px',
    right: '24px',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tab: {
    opacity: 1,
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    '&:last-child': {
      borderRight: 'none',
    },
    '&:first-child': {
      borderLeft: 'none',
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.custom.pageTabs.tabSelected,
    color: '#fff',
  },
});

export default withWidth()(withStyles(styles)(PageTabs));
