import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const OrdersTables = ({
  classes,
  data,
  onSelect,
  selectedRows,
  successItems,
  errorItems,
  errorItemIds,
  clearSuccessItems,
  page,
}) => {
  const { t } = useTranslation();
  const currency = data.length ? data[0].currency : '';
  const handleSelect = (event, id) => {
    clearSuccessItems();
    if (event.target.checked) {
      onSelect((prev) => [...prev, id]);
    } else {
      onSelect((prev) => prev.filter((value) => value !== id));
    }
  };

  const handleSelectAll = (event) => {
    clearSuccessItems();
    if (event.target.checked) {
      onSelect(data.map((value) => value.id));
    } else {
      onSelect([]);
    }
  };

  const checkIfSuccess = (id) =>
    selectedRows.includes(id) && successItems.includes(id);

  const checkIfError = (id) =>
    selectedRows.includes(id) && errorItemIds.includes(id);

  const getErrorMessage = (id) => {
    const errorItem = errorItems.find((item) => item.id === id);
    return errorItem ? errorItem.error : '';
  };

  return (
    <TableContainer
      component={Paper}
      variant="rounded"
      className={classes.tableContainer}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox onChange={handleSelectAll} />
            </TableCell>
            <TableCell>
              <div>{t('order')}</div>
              <div>{t('date')}</div>
            </TableCell>
            <TableCell>
              <div>{t('part_number')}</div>
              <div>
                <small>{t('brand')}</small>
              </div>
            </TableCell>
            <TableCell>{t('booking')}</TableCell>
            <TableCell>{t('delivery')}</TableCell>
            <TableCell>
              <div>{t('order_price')}</div>
              <div>{currency.trim()}</div>
            </TableCell>
            <TableCell>
              <div>{t('sale_price')}</div>
              <div>{currency.trim()}</div>
            </TableCell>
            <TableCell>{t('ordered')}</TableCell>
            <TableCell>{t('purchased')}</TableCell>
            <TableCell>{t('ready_to_ship')}</TableCell>
            <TableCell>{t('shipped')}</TableCell>
            <TableCell>{t('refused')}</TableCell>
            <TableCell>{t('reference')}</TableCell>
            <TableCell>{t('description')}</TableCell>
            <TableCell>{t('state_date')}</TableCell>
            <TableCell>{t('invoice')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(page * 500, (page + 1) * 500).map((value) => {
            const isError = checkIfError(value.id);
            return (
              <LightTooltip
                key={value.id}
                title={isError ? getErrorMessage(value.id) : ''}
                interactive
                placement="bottom-start"
              >
                <TableRow
                  key={value.id}
                  className={isError ? classes.errorRow : null}
                >
                  <TableCell>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        onChange={(event) => handleSelect(event, value.id)}
                        checked={selectedRows.includes(value.id)}
                        checkedIcon={isError ? <ErrorIcon /> : undefined}
                        className={
                          checkIfSuccess(value.id)
                            ? classes.checkboxSuccess
                            : isError
                            ? classes.checkboxError
                            : null
                        }
                      />
                      {value.refusal ? (
                        <Tooltip
                          title={t('refusal_indicator')}
                          classes={{
                            tooltip: classes.tooltip,
                          }}
                        >
                          <BlockIcon
                            style={{ width: 14, height: 14, color: 'red' }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>{value.orderId}</div>
                    <div>{value.orderDate.slice(0, 10)}</div>
                  </TableCell>
                  <TableCell>
                    <div>{value.partNumber}</div>
                    <div>
                      <small>{value.brand}</small>
                    </div>
                  </TableCell>
                  <TableCell>{value.catalog}</TableCell>
                  <TableCell>
                    {value.delivery} ({value.tariff})
                  </TableCell>
                  <TableCell>{value.priceOrder}</TableCell>
                  <TableCell>{value.priceSale}</TableCell>
                  <TableCell>{value.ordered}</TableCell>
                  <TableCell>{value.purchased}</TableCell>
                  <TableCell>{value.accepted}</TableCell>
                  <TableCell>{value.shipped}</TableCell>
                  <TableCell>{value.refused}</TableCell>
                  <TableCell>
                    <small>{value.yourReference}</small>
                  </TableCell>
                  <TableCell>
                    <small>{value.answer}</small>
                  </TableCell>
                  <TableCell>
                    {value.stateDate.slice(0, 16).replace('T', ' ')}
                  </TableCell>
                  <TableCell>{value.shipment}</TableCell>
                </TableRow>
              </LightTooltip>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrdersTables.defaultProps = {
  data: [],
};

const styles = (theme) => {
  return {
    table: {
      borderRadius: '10px',
      minHeight: 0,
      verticalAlign: 'top',
    },
    container: {
      width: '100%',
    },
    tableContainer: {
      margin: '0 auto',
      boxShadow: 'none',
      display: 'flex',
      flexGrow: 1,
      maxHeight: '100%',
      flexDirection: 'column',
      overflowY: 'auto',
      border: '1px solid #00000040',
      borderRadius: '10px',
      width: '100%',
    },
    tableHead: {
      backgroundColor: '#F2F2F7',
    },
    checkboxError: {
      color: 'red !important',
    },
    checkboxSuccess: {
      color: 'green !important',
    },
    errorRow: {
      backgroundColor: '#F2DEDEbb',
    },
    refusalIndicator: {
      width: 14,
      height: 14,
      borderRadius: '100%',
      color: 'red',
      position: 'absolute',
      top: '50%',
    },
    tooltip: {
      fontSize: 12,
    },
  };
};

export default withStyles(styles)(OrdersTables);
