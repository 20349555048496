import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as dictApi from '../api/dictionary-api';

import moment from 'moment';
import { APP_DATETIME_FORMAT } from '../utils/date';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@material-ui/core';

const CatalogTable = ({ classes, data }) => {
  const { t } = useTranslation();

  const urlBan =
    data.length &&
    !data[0].resourceUrl.startsWith('ftp') &&
    !data[0].resourceUrl.startsWith('http')
      ? data[0].resourceUrl
      : null;

  async function onDownloadClick(offer) {
    if (offer.resourceUrl.endsWith(offer.resourceFile)) {
      window.open(offer.resourceUrl, '_self');
    } else {
      dictApi.token(offer.resourceFile).then((value) => {
        const { token } = value;
        const url = offer.resourceUrl + token;
        window.open(url, '_self');
      });
    }
  }

  return (
    <div className={`${classes.container} search-table`}>
      <TableContainer
        component={Paper}
        variant="rounded"
        className={classes.tableContainer}
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>
                <div className={classes.catalogCell}>{t('offer')}</div>
              </TableCell>
              <TableCell>{t('brand')}</TableCell>
              <TableCell>{t('download')}</TableCell>
              <TableCell>{t('lines')}</TableCell>
              <TableCell>{t('updated')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((value, i) => (
              <TableRow
                key={value.id}
                style={{ backgroundColor: i % 2 === 0 ? '#FBFBFB' : '#FFFFFF' }}
              >
                <TableCell>
                  <div className={classes.catalogCell}>{value.offer}</div>
                </TableCell>
                <TableCell>{value.brand}</TableCell>
                <TableCell>
                  {urlBan !== null ? (
                    urlBan
                  ) : (
                    <Button
                      className={classes.downloadButton}
                      variant="contained"
                      component="a"
                      onClick={onDownloadClick.bind(null, data[i])}
                      //                    href={value.resourceUrl}
                      download
                    >
                      {t('download')}
                    </Button>
                  )}
                </TableCell>
                <TableCell>{value.lines}</TableCell>
                <TableCell>
                  {value.updated
                    ? moment(value.updated).format(APP_DATETIME_FORMAT)
                    : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  return {
    container: {
      flex: '1 0 0px',
      display: 'flex',
      flexGrow: 1,
      maxHeight: '100%',
      overflowY: 'auto',
    },
    table: {
      borderRadius: '10px',
    },
    tableContainer: {
      margin: '0 auto',
      boxShadow: 'none',
      border: '1px solid #00000040',
      borderRadius: '10px',
      [theme.breakpoints.up('xl')]: {
        width: '95%',
      },
    },
    tableHead: {
      backgroundColor: '#F2F2F7',
    },
    weightCell: {
      whiteSpace: 'nowrap',
    },
    weightValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`,
    },
    pointValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`,
    },
    ratingCell: {
      textAlign: 'left',
      padding: 20,
    },
    rating: {
      display: 'flex',
    },
    ratingIcon: {
      width: 20,
      height: 20,
    },
    ratingIconButton: {
      padding: 1,
    },
    quantityField: {
      alignItems: 'center',
    },
    downloadButton: {
      fontsize: '24px',
    },
    catalogCell: {
      textAlign: 'left',
      marginLeft: theme.spacing(1) * 2,
    },
  };
};

export default withStyles(styles)(CatalogTable);
