import React from 'react';
import { TextField } from './index';
import { withStyles } from '@material-ui/core/styles';

const StringField = ({ id, className, value, onChange }) => {
  let changeDelay = undefined;

  function handleChange(event) {
    clearTimeout(changeDelay);

    const { value } = event.target;
    if (onChange) {
      changeDelay = window.setTimeout(onChange, 500, id, value);
    }
  }

  function handleBlur(event) {
    event.target.value = value;
  }

  return (
    <TextField
      style={{ width: '10rem' }}
      className={className}
      defaultValue={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default StringField;
