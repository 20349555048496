import React from 'react';
import { withStyles } from '@material-ui/core';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { APP_DATE_FORMAT } from '../utils/date';
import { TextField } from '@material-ui/core';
const DatePickerToolbar = () => {};

const DatePicker = ({
  classes,
  className,
  forwardedRef,
  label,
  value,
  onChange,
  variant,
  ...rest
}) => {
  return (
    <MuiDatePicker
      ref={forwardedRef}
      variant={variant ? variant : 'inline'}
      orientation="portrait"
      className={className}
      label={label}
      value={value}
      format={APP_DATE_FORMAT}
      placeholder={APP_DATE_FORMAT.toLowerCase()}
      InputProps={{
        className: classes.input,
      }}
      InputLabelProps={{
        shrink: true,
        style: { color: '#000', fontWeight: 500, fontSize: '12px' },
      }}
      onChange={onChange}
      disabled={rest.disabled}
      minDate={rest.minDate}
      maxDate={rest.maxDate}
    />
  );
};

const styles = (theme) => ({
  input: {},
});

export default withStyles(styles)(DatePicker);
