import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const RefusalDialog = ({ classes, open, onClose, onConfirm, loading }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-refuseDialog-title"
      aria-describedby="alert-refuseDialog-description"
    >
      <DialogTitle className={classes.dialog} id="alert-refuseDialog-title">
        {t('refuseDialog.title')}
      </DialogTitle>
      <DialogActions className={classes.dialog}>
        <Button onClick={onClose} color="primary" className={classes.button}>
          {t('refuseDialog.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color="secondary"
          autoFocus
          className={classes.button}
        >
          {t('refuseDialog.confirm')}
        </Button>
        {loading && <CircularProgress></CircularProgress>}
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.signUpDialog;

  return {
    formContainer: {
      padding: '30px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '715px',
      margin: '0 auto 0 30px',
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '370px',
      },
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    searchHeading: {
      maxWidth: '350px',
      fontWeight: '500',
      fontSize: '22px',
      paddingBottom: '12px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    dialog: {
      backgroundColor: '#F2F2F7',
    },
    button: {
      ...theme.button.filled,
      minWidth: '85px',
      marginRight: '10px',
      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px',
    },

    errorChip: {
      color: colors.errorChip.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
    icon: {
      width: '14px',
      marginTop: '3px',
      marginRight: '5px',
      marginLeft: '10px',
    },
  };
};

export default withStyles(styles)(RefusalDialog);
