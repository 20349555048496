import React, { useEffect, useState } from 'react';
import * as userApi from '../api/user-api';
import * as dictionaryApi from '../api/dictionary-api';
import { isEnterKey } from '../utils/keyEvents';
import { useTranslation, Trans } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import {
  TextField,
  Button,
  Chip,
  withStyles,
  useTheme,
  useMediaQuery,
  MenuItem,
  Grid,
  Stepper,
  Step,
  Typography,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Captcha from '../components/Captcha';
import { Page } from '../components';
import { getCookie } from '../utils/cookies';
import img_header from '../assets/image/image_header_reg.png';
import activation_step_img from '../assets/image/activation_step_img.png';
import final_step_img from '../assets/image/final_step_img.png';

import StepConnector from '@material-ui/core/StepConnector';

const DotConnector = withStyles({
  line: {
    border: 'none',
    minHeight: '28px',
    flex: 1,
    backgroundImage: `radial-gradient(circle, #D1D1D1 1px, transparent 3px)`,
    backgroundSize: `10px 10px`,
    backgroundRepeat: 'repeat-y',
    alignSelf: 'center',
  },
})(StepConnector);

const RegisterPage = ({ classes, open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    captcha: '',
    currencyCode: getCookie('currency'),
  });
  const [userAddData, setUserAddData] = useState({
    username: '',
    countryCode: '',
    zipCode: '',
    city: '',
    state: '',
    street: '',
    building: '',
    flat: '',
    phone: '',
    contactPerson: '',
    contactPhone: '',
    contactEMail: '',
    trn: '',
    currencyCode: '',
    customerCode: '',
  });
  const [formError, setFormError] = useState('');
  const [captcha, setCaptcha] = useState(null);
  const [invalid, setInvalid] = useState('');
  const [error, setError] = useState('');
  // const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('access_token'));
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );
  const [signUpError, setSignUpError] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [isUserDataDialogOpen, setUserDataDialogOpen] = useState(false);
  const [isUserDataSubmitting, setUserDataSubmitting] = useState(false);
  const [submitUserDataError, setSubmitUserDataError] = useState('');

  const [countryList, setCountryList] = useState([]);

  const steps = [t('register_step1'), t('register_step2'), t('register_step3')];
  const [activeStep, setActiveStep] = useState(isLoggedIn ? 3 : 0);

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  useEffect(() => {
    fetchCaptcha();
  }, [signUpError]);

  useEffect(() => {
    if (activeStep == 3) {
      const user = { ...userData };
      delete user.confirmPassword;
      const userData_merged = mergeSharedKeys(userAddData, user);

      handleFinishRegistration();
    }
  }, [activeStep]);

  useEffect(() => {
    if (error) {
      fetchCaptcha();
      setUserData({ ...userData, captcha: '' });
    }
  }, [error]);

  useEffect(() => {
    if (error || formError) {
      setActiveStep(0);
    }
  }, [error, formError]);

  useEffect(() => {
    const checkLogin = setInterval(
      () =>
        isLoggedIn !== !!localStorage.getItem('access_token') &&
        setIsLoggedIn(!isLoggedIn),
      500
    );

    try {
      getCountryList();
      getCurrencyList();
    } catch (error) {
      //console.log(error);
    }
    return () => clearInterval(checkLogin);
  }, [isLoggedIn]);

  async function fetchCaptcha() {
    const fetchedCaptcha = await userApi.getCaptcha();
    setCaptcha(fetchedCaptcha);
  }

  async function getCurrencyList() {
    setCurrencyList([]);
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  async function handleFinishRegistration() {
    setFormError('');
    setError('');
    const user = { ...userData };
    delete user.confirmPassword;
    const userData_merged = mergeSharedKeys(userAddData, user);
    try {
      await handleSignUp(user);
      await handleSubmitUserData(userData_merged);
    } catch (error) {
      console.log(error);
      setFormError(error.message);
      setError(error.message);
    }
  }

  async function handleSignUp(user) {
    try {
      setSubmitting(true);
      const { currencyCode } = user;
      delete user.currencyCode;
      await userApi.signUp(user);
      try {
        const res = await userApi.login(user.email, user.password);
      } catch (error) {
        setError(error.message);
      }
      setSubmitting(false);
      // window.location.reload();
    } catch (error) {
      setActiveStep(0);
      setSignUpError(error.message);
      setSubmitting(false);
    }
  }

  const validatePassword = (password) => {
    // Check the length of the password
    if (password.length < 8) {
      alert('Password must be at least 8 characters');
      return false;
    }

    // Check for special character
    if (!/[!@#$%^&*(),.?":{}|<>]/g.test(password)) {
      alert('Password must contain at least one special character');
      return false;
    }

    // Check for a digit
    if (!/\d/.test(password)) {
      alert('Password must contain at least one digit');
      return false;
    }

    return true;
  };

  async function handleSubmitUserData(data) {
    try {
      setUserDataSubmitting(true);
      let res = await userApi.setData(data);
      setUserDataSubmitting(false);
    } catch (error) {
      setSubmitUserDataError(error.message);
      setUserDataSubmitting(false);
    }
  }

  function handleSubmit() {
    // Check if all fields has value
    //if (Object.keys(userData).some((key) => !userData[key])) return;

    let notValidField = '';
    let notValidHint = '';

    Object.keys(userData).forEach((key) => {
      if (key !== 'password' && key !== 'confirmPassword') {
        if (!/^[a-zA-Z0-9+@._\- ]*$/.test(userData[key])) {
          notValidField = key;
          notValidHint = t('fill_latin');
          return;
        }
      }
    });

    if (notValidField == '') {
      if (!validatePassword(userData.password)) {
        notValidField = 'password';
        notValidHint = t('error_password_not_match');
      }
    }

    if (notValidField == '') {
      if (userData.password !== userData.confirmPassword) {
        notValidField = 'confirmPassword';
        notValidHint = t('error_password_not_match');
      }
    }

    if (notValidField !== '') {
      setInvalid(notValidField);
      setFormError(notValidHint || t('fill_required'));
      return;
    }

    setInvalid('');
    setFormError('');
    setActiveStep(activeStep + 1);
  }

  function handleFieldChange(event) {
    const { target } = event;
    setUserData({ ...userData, [target.name]: target.value });
  }

  function handleAdditionalFieldChange(event) {
    const { target } = event;
    setUserAddData({ ...userAddData, [target.name]: target.value });
  }

  function handlePhoneChange(phoneValue) {
    setUserData((prevUserData) => ({
      ...prevUserData,
      phone: '+' + phoneValue,
    }));
  }

  function mergeSharedKeys(obj1, obj2) {
    let mergedObj = { ...obj1 };

    Object.keys(obj2).forEach((key) => {
      if (mergedObj.hasOwnProperty(key)) {
        mergedObj[key] = obj2[key];
      }
    });

    return mergedObj;
  }

  const submitError = error || formError || signUpError;
  const disabled =
    isSubmitting || Object.keys(userData).some((key) => !userData[key]);
  const disabledPrev = activeStep < 1;
  const isAnyFieldSet = Object.values(userAddData).some(
    (value) => value !== '' && value !== false
  );

  if (isLoggedIn) {
    return <Redirect to="/search" />;
  }

  return (
    <Page>
      {/* {isLoggedIn && <Redirect to="/search" />} */}
      <Grid container>
        <Grid xs={12} item>
          <div className={classes.header}>
            <Grid item className={classes.headerCell}>
              <Typography variant="h5" className={classes.headerTitle}>
                {t('register')}
              </Typography>
              <Typography variant="p" className={classes.headerSubTitle}>
                {t('header_have_account')}
              </Typography>
              <Link to="/login">
                <Button className={classes.headerButton}>{t('login')}</Button>
              </Link>
            </Grid>
          </div>
        </Grid>

        <Grid item md={6} xs={12} className={classes.stepperContainer}>
          <Typography variant="h5" className={classes.searchHeading}>
            {t('header_step')}
          </Typography>
          <Typography variant="p" className={classes.textLabel}>
            {t('provide_registration_data_text')}
          </Typography>
          <Stepper
            connector={
              <DotConnector
                classes={{
                  vertical: classes.stepperLine,
                }}
              />
            }
            activeStep={activeStep}
            classes={{
              root: classes.stepper,
              vertical: classes.stepperLine,
            }}
            //className={classes.stepper}
            orientation="vertical"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  classes={{}}
                  StepIconProps={{
                    classes: {
                      completed: classes.completed,
                      active: classes.active,
                      text: classes.text,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item md={6} xs={12} className={classes.formContainer}>
          {activeStep === 0 ? (
            <form>
              {!!submitError && (
                <Chip
                  className={classes.errorChip}
                  color="secondary"
                  icon={<ErrorIcon />}
                  label={submitError}
                />
              )}
              <Grid item container>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.searchHeading}>
                    {t(steps[activeStep])}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('email')}
                  </Typography>
                  <TextField
                    name="email"
                    type="email"
                    autoComplete="current-email"
                    className={classes.textField}
                    variant="outlined"
                    placeholder={'example@gmail.com'}
                    margin="normal"
                    fullWidth
                    value={userData.email}
                    onChange={handleFieldChange}
                    error={invalid === 'email'}
                  />
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={12}
                  xs={12}
                  className={classes.phoneContainer}
                >
                  <Typography variant="p" className={classes.textLabel}>
                    {t('contact_phone')}
                  </Typography>
                  <PhoneInput
                    preferredCountries={['ae', 'ua', 'kz']}
                    name="phone"
                    specialLabel={''}
                    country={'ae'}
                    containerClass={classes.phoneContainer}
                    //inputClass={classes.phoneInput}
                    inputStyle={{
                      borderRadius: '10px',
                      width: '280px',
                      height: '40px',
                    }}
                    value={userData.phone}
                    onChange={handlePhoneChange}
                  />
                </Grid>

                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('password')}
                  </Typography>
                  <TextField
                    name="password"
                    className={classes.textField}
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    placeholder={'••••••••'}
                    margin="normal"
                    fullWidth
                    value={userData.password}
                    onChange={handleFieldChange}
                    error={invalid === 'password'}
                  />

                  <List className={classes.list}>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_requiremests_head')}
                      />
                    </ListItem>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_at_least_chars')}
                      />
                    </ListItem>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_at_least_special')}
                      />
                    </ListItem>
                    <ListItem className={classes.list}>
                      <ListItemText
                        classes={{ root: classes.listTextRoot }}
                        primary={t('password_at_least_digit')}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('confirm_password')}
                  </Typography>
                  <TextField
                    name="confirmPassword"
                    className={classes.textField}
                    type="password"
                    autoComplete="new-password"
                    variant="outlined"
                    placeholder={'••••••••'}
                    margin="normal"
                    fullWidth
                    value={userData.confirmPassword}
                    onChange={handleFieldChange}
                    error={invalid === 'confirmPassword'}
                  />
                </Grid>

                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('company_name')}
                  </Typography>
                  <TextField
                    name="username"
                    autoComplete=""
                    className={classes.textField}
                    variant="outlined"
                    placeholder={'All Car Parts1'}
                    margin="normal"
                    fullWidth
                    value={userData.username}
                    onChange={handleFieldChange}
                    error={invalid === 'username'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('choose_currency')}
                  </Typography>
                  <RadioGroup
                    name="currencyCode"
                    value={userData.currencyCode}
                    onChange={handleFieldChange}
                    row
                  >
                    {currencyList.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio color="#000000" />} // use "secondary" if you want the secondary color
                        label={item.value}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                <Grid item lg={6} md={12} xs={12}>
                  <Captcha
                    captcha={captcha}
                    value={userData.captcha}
                    textFieldClassName={classes.textField}
                    onChange={handleFieldChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={8} className={classes.buttonContainer}>
                <span>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={disabledPrev}
                    onClick={_handleBack}
                  >
                    {t('prev_step')}
                  </Button>

                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={disabled}
                    onClick={handleSubmit}
                  >
                    {t('next_step')}
                  </Button>
                </span>
              </Grid>
            </form>
          ) : null}
          {activeStep === 1 ? (
            <form>
              <Grid item container>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.searchHeading}>
                    {t(steps[activeStep])}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_country')}
                  </Typography>
                  <TextField
                    name="countryCode"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    select
                    placeholder={t('not_selected')}
                    value={userAddData.countryCode}
                    onChange={handleAdditionalFieldChange}
                    required={true}
                    error={invalid === 'countryCode'}
                  >
                    {countryList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_zip')}
                  </Typography>
                  <TextField
                    name="zipCode"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    placeholder="05000"
                    fullWidth
                    value={userAddData.zipCode}
                    onChange={handleAdditionalFieldChange}
                    error={invalid === 'zipCode'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_state')}
                  </Typography>
                  <TextField
                    name="state"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    placeholder={t('california')}
                    fullWidth
                    value={userAddData.state}
                    onChange={handleAdditionalFieldChange}
                    error={invalid === 'state'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_city')}
                  </Typography>
                  <TextField
                    name="city"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    placeholder={t('san_francisco')}
                    fullWidth
                    value={userAddData.city}
                    onChange={handleAdditionalFieldChange}
                    required={true}
                    error={invalid === 'city'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_street')}
                  </Typography>
                  <TextField
                    name="street"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    placeholder="Main st."
                    fullWidth
                    value={userAddData.street}
                    onChange={handleAdditionalFieldChange}
                    error={invalid === 'street'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_building')}
                  </Typography>
                  <TextField
                    name="building"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    placeholder="54"
                    fullWidth
                    value={userData.building}
                    onChange={handleAdditionalFieldChange}
                    error={invalid === 'building'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_flat')}
                  </Typography>
                  <TextField
                    name="flat"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    placeholder="32A"
                    value={userAddData.flat}
                    onChange={handleAdditionalFieldChange}
                    error={invalid === 'flat'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_contact_person')}
                  </Typography>
                  <TextField
                    name="contactPerson"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    placeholder={t('contact_person')}
                    value={userAddData.contactPerson}
                    onChange={handleAdditionalFieldChange}
                    error={invalid === 'contactPerson'}
                  />
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                  <Typography variant="p" className={classes.textLabel}>
                    {t('user_tax')}
                  </Typography>
                  <TextField
                    name="trn"
                    autoComplete=""
                    margin="normal"
                    className={classes.textField}
                    variant="outlined"
                    placeholder="97612567"
                    fullWidth
                    value={userAddData.trn}
                    onChange={handleAdditionalFieldChange}
                  />
                </Grid>
              </Grid>
              <Typography variant="p" className={classes.bottomSkipText}>
                {t('fill_later')}
              </Typography>
              <Grid item xs={12} className={classes.buttonContainer}>
                <span>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={disabledPrev}
                    onClick={_handleBack}
                  >
                    {t('prev_step')}
                  </Button>

                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={!isAnyFieldSet}
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    {t('next_step')}
                  </Button>
                </span>
                <span>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    {t('skip')}
                  </Button>
                </span>
              </Grid>
            </form>
          ) : null}
          {activeStep === 2 ? (
            <Grid item container>
              <Grid item lg={8} md={12}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t(steps[activeStep])}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.step3Subheadin}
                >
                  <Trans i18nKey="activation_sub_title1">
                    Активировав свой аккаунт, Вы сможете создавать заказы и
                    скачивать
                    <Link
                      to="/prices"
                      style={{ textDecoration: 'none', color: '#007AFF' }}
                    >
                      прайс-листы
                    </Link>
                  </Trans>
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.step3Subheadin}
                >
                  <Trans i18nKey="activation_sub_title2">
                    Для активации аккаунта необходимо пополнить кошелёк на сумму
                    <strong>$500</strong>
                  </Trans>
                </Typography>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleFinishRegistration}
                >
                  {t('topup')}
                </Button>
                {isMobile ? (
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="p" className={classes.bottomSkipText}>
                      {t('top_up_later')}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item lg={4} md={12}>
                <img src={activation_step_img} className={classes.formImg} />
              </Grid>
              {isMobile ? null : (
                <Grid item xs={12}>
                  <Typography variant="p" className={classes.bottomSkipText}>
                    {t('top_up_later')}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} className={classes.buttonContainer}>
                <span>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    disabled={disabledPrev}
                    onClick={_handleBack}
                  >
                    {t('prev_step')}
                  </Button>
                </span>
                <span>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    {t('skip')}
                  </Button>
                </span>
              </Grid>
            </Grid>
          ) : null}
          {activeStep === 3 && !isSubmitting ? (
            <Grid item container>
              <Grid item lg={8} md={12}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t('register_step4')}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.step3Subheadin}
                >
                  <Trans i18nKey="activation_sub_title4">
                    Если у Вас есть вопросы, Вы можете связаться с нами по почте
                    <a
                      href="mailto:sales@allcarparts.ae"
                      style={{ textDecoration: 'none', color: '#007AFF' }}
                    >
                      sales@allcarparts.ae
                    </a>
                  </Trans>
                </Typography>
              </Grid>
              <Grid item lg={5} md={12}></Grid>

              <Grid item lg={7} md={12}>
                <img src={final_step_img} className={classes.formFinalImg} />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.signUpDialog;

  return {
    formContainer: {
      boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.10)',
      padding: '30px',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '715px',
      margin: '0 auto 0 30px',
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '370px',
      },
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    header: {
      margin: '60px auto',
      height: '192px',
      width: '85%',
      borderRadius: '20px',
      padding: '0',
      overflow: 'hidden',
      backgroundImage: `url(${img_header})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [theme.breakpoints.down('lg')]: {
        width: '90%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: '0px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%',
      },
    },
    headerCell: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'clamp(340px,30%,50%)',
      height: '100%',
      backdropFilter: 'blur(20px)',
      padding: '30px 5px 15px 30px',
    },
    headerTitle: {
      fontWeight: '500',
      fontSize: '40px',
      fontWeight: '600',
      color: '#FFFFFF',
      textAlign: 'left',
      paddingBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    headerSubTitle: {
      color: '#FFFFFF',
      fontWeight: '400',
      fontSize: '12px',
    },
    headerButton: {
      color: '#FFFFFF',
      minWidth: '80px',
      height: '24px',
      background: '#ffffff40',
      fontSize: '12px',
      margin: '10px 0',
      borderRadius: '100px',
      fontWeight: '400',
      textTransform: 'capitalize',
    },

    button: {
      ...theme.button.filled,
      minWidth: '85px',
      marginRight: '10px',
      marginBottom: '10px',
      '& > *': {
        fontWeight: '500',
        fontSize: '12px',
      },
      '&.Mui-disabled': {
        ...theme.button.transparent,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
    nameContainer: {
      display: 'flex',
    },
    stepper: {
      background: '#F2F2F7',
      padding: '30px 0',
    },
    stepperLine: {
      marginLeft: '6px',
      marginTop: '2px',
    },
    completed: {
      color: '#34C759 !important',
    },
    active: {
      color: '#fff !important',
    },
    text: {
      fill: '#000000 !important',
    },
    stepperContainer: {
      background: '#F2F2F7',
      borderRadius: '20px',
      padding: '30px',
      maxWidth: '465px',
      height: '570px',
      margin: '0 30px 0 auto',
      [theme.breakpoints.down('lg')]: {
        margin: '0 auto',
        height: '340px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '370px',
        marginBottom: '30px',
      },
      [theme.breakpoints.up('xl')]: {
        margin: '0 auto',
      },
    },
    phoneContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '10px',
      height: '100%',
    },
    phoneInput: {
      width: '240px',
      height: '40px',
      borderRadius: '10px',
    },
    nameTextField: {
      marginTop: 0,
      backgroundColor: colors.textField.background,
      '&:first-child': {
        marginRight: theme.spacing(1),
      },
    },
    labelField: {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
    },
    textField: {
      '& > *': {
        borderRadius: '10px',
        height: '40px',
        width: '280px',
      },
    },

    list: {
      color: '#00000080',
      fontSize: '8px',
      padding: '0',
      margin: '0',
    },
    listTextRoot: {
      padding: '0',
      margin: '0',
      '& > *': {
        fontSize: '10px !important',
      },
    },
    formImg: {
      width: '113%',
      paddingTop: '130px',
      paddingBottom: '20px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingTop: '30px',
      },
    },
    formFinalImg: {
      width: '95%',
      transform: 'translateX(28px)',
      paddingTop: '30px',
      paddingBottom: '60px',
      float: 'right',
      [theme.breakpoints.down('md')]: {
        transform: 'none',
        width: '100%',
        paddingTop: '30px',
        paddingBottom: '10px',
        float: 'left',
      },
    },
    bottomSkipText: {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '35px',
      color: '#00000080',
      fontWeight: '400',
      [theme.breakpoints.down('md')]: {
        lineHeight: '24px',
        marginTop: '20px',
      },
    },
    searchHeading: {
      fontWeight: '500',
      fontSize: '22px',
      textAlign: 'left',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    step3Subheadin: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '10px',
      marginBottom: '12px',
    },
    textLabel: {
      fontSize: '14px',
      marginBottom: '10px',
    },
    errorChip: {
      color: colors.errorChip.color,
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1) * 2,
    },
  };
};

export default withStyles(styles)(RegisterPage);
