import React from 'react';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const PageFooter = ({ classes }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  function handlePolicyClick() {
    window.open(`/manuals/${i18n.language}/policy.pdf`);
    return false;
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const aYear = new Date().getFullYear();

  return (
    <div className={classes.container}>
      <div className={classes.footerUp}>
        {isMobile && (
          <Typography variant="caption" className={classes.link}>
            {`(C) ${window.COMPANY_NAME}, 2022-${aYear}.`}{' '}
            <a href="https://www.bresatec.com" target="_blank">
              Powered by SmartTrade
            </a>
          </Typography>
        )}
      </div>
      <hr width="85%" size="1" align="center"></hr>

      <div className={classes.footer}>
        <Toolbar className={classes.toolbar}>
          {!isMobile && (
            <Typography variant="caption" className={classes.link}>
              {`(C) ${window.COMPANY_NAME}, 2022-${aYear}.`}{' '}
              <a href="https://www.bresatec.com" target="_blank">
                Powered by SmartTrade
              </a>
            </Typography>
          )}
          {/* <Typography variant="subtitle2" className={classes.link}>
            <Link to="/manuals">{t('manuals')}</Link>
         </Typography> */}
          {/* <Typography variant="subtitle2" className={classes.link}>
            <Link to="/about">{t('about_us')}</Link>
          </Typography> */}
          <span className={classes.links}>
            <Typography variant="subtitle2" className={classes.link}>
              <a href="#" onClick={handlePolicyClick}>
                {t('terms_and_conditions')}
              </a>
            </Typography>
            <Typography variant="subtitle2" className={classes.link}>
              |
            </Typography>
            <Typography variant="subtitle2" className={classes.link}>
              <Link to="/contacts">{t('contacts')}</Link>
            </Typography>
            <Typography variant="subtitle2" className={classes.link}>
              |
            </Typography>
            <Typography variant="subtitle2" className={classes.link}>
              <Link to="/manuals">{t('manuals')}</Link>
            </Typography>
          </span>
        </Toolbar>
      </div>
      {/* <div className={classes.copyright}>
        
      </div> */}
    </div>
  );
};
/*
<div className={classes.paylogo}>
  <img src="/visa-pay-logo.svg" />
  <img src="/master-card-logo.svg" />
</div>
*/
const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;
  return {
    container: {
      paddingTop: 50,
      marginTop: 40,
      backgroundColor: '#F2F2F7',
    },
    footer: {
      width: '80%',
      margin: '0 auto',
      minHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '24px',
      },
    },
    copyright: {
      minHeight: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '12px',
      },
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5),
      marginRight: theme.spacing(3),
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-between',
      minHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    footerUp: {
      display: 'flex',
      justifyContent: 'center',
    },
    name: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing(1),
    },
    paylogo: {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        height: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        '& > img': {
          height: '20px',
        },
      },
    },
    address: {
      color: theme.palette.custom.logo,
      fontWeight: 'normal',
    },
    link: {
      color: theme.palette.custom.logo,
      fontWeight: '400',
      fontSize: '14px',
    },
    links: {
      display: 'flex',
      justifyContent: 'space-around',
      gap: '10px',
    },
  };
};

export default withStyles(styles)(PageFooter);
