import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { getCookie } from '../utils/cookies';
import { useNotificationContext } from '../utils/userNotificationContext';
import {
  withStyles,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import Slider from 'react-slick';
import {
  Page,
  SearchBlock,
  CardComponent,
  TimelineComponent,
  SlickSlider,
  HugeCard,
  NewsCard,
  ContactSection,
  Breadcrumb,
} from '../components';

import { newsList } from '../api/news-api';

import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import NearMeIcon from '@material-ui/icons/NearMe';
import PeopleIcon from '@material-ui/icons/People';
import hero_image from '../assets/image/hero_image.png';
import laptop from '../assets/image/laptop.png';
import fast_delivery_icon from '../assets/image/fast_delivery_icon.png';
import international_icon from '../assets/image/international_icon.png';
import price_list_icon from '../assets/image/price_list_icon.png';
import quality_control_icon from '../assets/image/quality_control_icon.png';
import variety_icon from '../assets/image/variety_icon.png';
import buy_sell_icon from '../assets/image/buy_sell_icon.png';
import CancelIcon from '@material-ui/icons/Cancel';

const brandImages = [
  '/images/brands/bmw.png',
  '/images/brands/audi.jpg',
  '/images/brands/ford.png',
  '/images/brands/honda.png',
  '/images/brands/hyundai.jpg',
  '/images/brands/mazda.png',
  '/images/brands/mercedes.jpg',
  '/images/brands/mitsubishi.png',
  '/images/brands/nissan.png',
  '/images/brands/porsche.jpg',
  '/images/brands/suzuki.png',
  '/images/brands/subaru.jpg',
  '/images/brands/toyota.jpg',
];

const HomePage = ({ classes, history, location, onSignUp }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isNewsDialogOpen, setIsNewsDialogOpen] = useState(true);

  const { newsCardsData, newsCardsDataAll, fetchNewsList } =
    useNotificationContext();

  useEffect(() => {
    fetchNewsList();
  }, [i18n.language]);

  function handleSearchButtonClick() {
    history.push({
      pathname: '/search',
    });
  }

  function handleRegisterButtonClick() {
    history.push({
      pathname: '/register',
    });
  }
  const handleClose = () => {
    setIsNewsDialogOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000, // screen width 1000px or less
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 675, // screen width 600px or less
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //TODO: ADD CONTEXT
  return (
    <Page>
      <div>
        <Helmet>
          <title>
            Home - AllCarParts - Find out car spare parts, you are looking for -
            availability, prices and delivery options
          </title>
          <meta
            name="description"
            content="Search by a single part number or get quotation for the entire list of part numbers, with possible cross references"
          ></meta>
        </Helmet>
      </div>
      <Dialog
        open={newsCardsData.length && isNewsDialogOpen && isLoggedIn}
        fullWidth
        maxWidth="sm"
        style={{ backdropFilter: 'blur(12px)' }}
        classes={{
          paper: classes.DialogPaper,
        }}
      >
        <DialogActions>
          <IconButton
            style={{ marginRight: '0px' }}
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CancelIcon fontSize="medium" />
          </IconButton>
        </DialogActions>
        <Typography variant="h5" classes={{ h5: classes.dialogHeading }}>
          {t('new_news')}
        </Typography>
        {newsCardsData.slice(0, 1).map((card, index) => (
          <Grid
            key={index}
            item
            className={classes.cards}
            style={{ padding: ' 0 20px' }}
          >
            <NewsCard
              id={card.id}
              date={card.date.split('T')[0]}
              content={card.text}
              subTitle={card.title}
            />
          </Grid>
        ))}
      </Dialog>
      <SearchBlock history={history} location={location}></SearchBlock>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={6} md={6} sm={12}>
          <div className={classes.heroText}>
            <Typography variant="h3" className={classes.heroHeading}>
              {t('hero_text_heading')}
            </Typography>
            <Typography variant="h6" className={classes.heroSubHeading}>
              {t('hero_text_subheading')}
            </Typography>

            {isLoggedIn ? (
              <Button
                className={classes.heroButton}
                onClick={handleSearchButtonClick}
              >
                {t('search')}
              </Button>
            ) : (
              <Button
                className={classes.heroButton}
                onClick={handleRegisterButtonClick}
              >
                {t('hero_register')}
              </Button>
            )}
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <img src={hero_image} className={classes.heroImage}></img>
        </Grid>
      </Grid>

      <hr style={{ width: '85%' }} />
      <Typography
        variant="h5"
        style={{ marginBottom: '60px' }}
        classes={{ h5: classes.sectionHeading }}
      >
        {t('how_it_works')}
      </Typography>

      <Grid container justifyContent="center" style={{ marginBottom: '60px' }}>
        <Grid item lg={3} md={4} sm={12} className={classes.cards}>
          <CardComponent
            alignCenter
            content={t('card_search_parts_text')}
            title={t('card_search_parts')}
            icon={<SearchIcon />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={12} className={classes.cards}>
          <CardComponent
            alignCenter
            content={t('place_order_text')}
            title={t('place_order')}
            icon={<ShoppingCartIcon />}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={12} className={classes.cards}>
          <CardComponent
            alignCenter
            content={t('track_order_text')}
            title={t('track_order')}
            icon={<NearMeIcon />}
            action={<Link to="/search">{t('card1Action')}</Link>}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        className={classes.sectionGrey}
      >
        <Grid item lg={12} sm={12} xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
            {t('how_to_start')}
          </Typography>
        </Grid>
        <Grid item lg={12} md={10} xs={12}>
          <TimelineComponent />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" className={classes.sectionWhite}>
        <Grid item lg={12} sm={12} xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
            {t('what_can_it_do')}
          </Typography>
          <Typography variant="h5" classes={{ h5: classes.sectionSubHeading }}>
            {t('each_option_better')}
          </Typography>
        </Grid>

        <Grid item lg={3} md={6} sm={12} className={classes.cards}>
          <CardComponent
            action={<Link to="/search">{t('card1Action')}</Link>}
            title={t('card1Text')}
            icon={<SearchIcon />}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} className={classes.cards}>
          <CardComponent
            action={<Link to="/offers">{t('card2Action')}</Link>}
            title={t('card2Text')}
            icon={<img src={price_list_icon} />}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} className={classes.cards}>
          <CardComponent
            action={<Link to="/search">{t('card1Action')}</Link>}
            title={t('card3Text')}
            icon={<img src={quality_control_icon} />}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12} className={classes.cards}>
          <CardComponent
            action={<Link to="/contacts">{t('card4Action')}</Link>}
            title={t('card4Text')}
            icon={<img src={international_icon} />}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} className={classes.cards}>
          <CardComponent
            action={<Link to="/search">{t('card5Action')}</Link>}
            title={t('card5Text')}
            icon={<img src={fast_delivery_icon} />}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} className={classes.cards}>
          <CardComponent
            action={<Link to="/search">{t('card1Action')}</Link>}
            title={t('card6Text')}
            icon={<img src={variety_icon} />}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            classes={{ h5: classes.laptopSectionSubHeading }}
          >
            {t('main_advantage')}
          </Typography>
          <Typography
            variant="h5"
            classes={{ h5: classes.laptopSectionHeading }}
          >
            {t('data_in_the_palm')}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <img src={laptop} className={classes.laptopImage}></img>
        </Grid>
      </Grid>

      <Grid container className={classes.sectionGrey}>
        <Grid item xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
            {t('brief_about_us')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.aboutText}>
            {t('about_us_text1')}
          </Typography>
          <Typography variant="subtitle1" className={classes.aboutText}>
            {t('about_us_text2')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.slideContainer}>
          <SlickSlider items={brandImages} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
            {t('watch_our_promo')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.videoContainer}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/CWr5VwZgXHc"
              title="YouTube AllCarParts promo"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </Grid>
      </Grid>

      {1 == 0 && (
        <Grid container className={classes.hugeCardsContainer}>
          <Grid item lg={6} sm={12} className={classes.hugeCardNarrow}>
            <HugeCard
              content={t('hugeCard1Text')}
              title={t('hugeCard1Title')}
              icon={<PeopleIcon />}
            />
          </Grid>
          <Grid item lg={6} sm={12} className={classes.hugeCardNarrow}>
            <HugeCard
              content={t('hugeCard2Text')}
              title={t('hugeCard2Title')}
              icon={<NearMeIcon />}
            />
          </Grid>
          <Grid item xs={12} className={classes.hugeCardWide}>
            <HugeCard
              wide
              content={t('hugeCard3Text')}
              title={t('hugeCard3Title')}
              icon={<img src={buy_sell_icon} />}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justifyContent="center"
        className={`${classes.sectionGrey}`}
        style={{ marginBottom: '60px' }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" classes={{ h5: classes.sectionHeading }}>
            {t('news_section')}
          </Typography>
          <Typography
            variant="h5"
            classes={{ h5: classes.sectionSubHeading }}
          ></Typography>
        </Grid>
        <Slider {...settings} className={classes.SlickSlider}>
          {newsCardsDataAll.slice(0, 3).map((card, index) => (
            <Grid key={index} item className={classes.cards}>
              <NewsCard
                id={card.id}
                date={card.date.split('T')[0]}
                content={card.text}
                subTitle={card.title}
              />
            </Grid>
          ))}
        </Slider>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            classes={{ h5: classes.sectionSubHeading }}
            style={{
              textDecoration: 'underline',
              color: '#00000088',
              cursor: 'pointer',
            }}
          >
            {t('check_all_news')}
          </Typography>
        </Grid>
      </Grid>

      <ContactSection />
    </Page>
  );
};

const styles = (theme) => {
  const paddings = {
    [theme.breakpoints.up('md')]: {
      marginLeft: '15%',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  };
  return {
    sectionGrey: {
      backgroundColor: '#F2F2F7',
    },
    DialogPaper: {
      backgroundColor: '#F2F2F7',
      overflow: 'hidden',
    },
    dialogHeading: {
      maxWidth: '80%',
      margin: ' 20px auto',
      fontSize: '2rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '1.5rem',
        margin: ' 10px auto',
      },
    },
    cardDialog: {
      padding: '0 10px',
    },
    sectionHeading: {
      marginTop: '60px',

      fontSize: '2rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    SlickSlider: {
      display: 'block',
      width: '90%',
      height: 'auto !important' /* Set height to auto */,

      [theme.breakpoints.down('md')]: {
        width: '95%',
        display: 'inline-block !important',
      },
      [theme.breakpoints.up('lg')]: {
        width: '85%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%',
      },
    },
    sectionSubHeading: {
      marginBottom: '50px',
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    laptopSectionSubHeading: {
      marginTop: '25px',
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    laptopSectionHeading: {
      fontSize: '2rem',
      marginTop: '10px',
      marginBottom: '25px',
    },
    laptopImage: {
      width: '75%',
      display: 'flex',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    heroImage: {
      width: '100%',
    },
    heroText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    heroHeading: {
      ...paddings,
      fontSize: '2.3rem',
      fontWeight: 700,
      width: '80%',
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        marginTop: '30px',
        width: '75%',
        textAlign: 'center',
      },
    },
    heroSubHeading: {
      ...paddings,
      letterSpacing: '0px',
      color: '#000',
      marginTop: '16px',
      fontSize: '22px',
      fontWeight: 400,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '65%',
      },
    },
    heroButton: {
      ...paddings,
      minWidth: '135px',
      marginTop: '16px',
      fontSize: '21px',
      textTransform: 'capitalize',
      background: '#007AFF',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        background: '#007AFF15',
        color: '#007AFF',
      },
    },
    cards: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '2rem',
      marginRight: '1.5rem',
      [theme.breakpoints.down('md')]: {
        marginRight: '0px',
      },
    },

    slideContainer: {
      background: '#ffffff',
    },
    aboutText: {
      margin: '1rem auto',
      width: '50%',
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    hugeCardsContainer: {
      margin: '2rem auto',
      display: 'flex',
      justifyContent: 'center',
    },
    hugeCardNarrow: {
      margin: '10px 0',
      justifyContent: 'center',
      display: 'flex',
      [theme.breakpoints.up('xl')]: {
        flexBasis: '25%',
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: '32%',
      },
      [theme.breakpoints.down('lg')]: {
        flexBasis: '41%',
      },
      [theme.breakpoints.down('md')]: {
        flexBasis: '50%',
      },
    },
    hugeCardWide: {
      margin: '10px 0',
      justifyContent: 'center',
      display: 'flex',
    },
    contactData: {
      ...paddings,
      letterSpacing: '0px',
      color: '#000',
      marginTop: '16px',
      fontSize: '22px',
      fontWeight: 500,
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '65%',
      },
    },

    videoContainer: {
      width: '65%',
      height: '700px',
      background: '#808080',
      margin: '2rem auto',
      borderRadius: '20px',
      [theme.breakpoints.down('md')]: {
        width: '75%',
        height: '500px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '75%',
        height: '400px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '85%',
        height: '300px',
      },
    },
  };
};

export default withStyles(styles)(HomePage);
