import React, { useEffect, useMemo, useState, useRef } from 'react';
import querystring from 'querystring-es3';
import { withStyles } from '@material-ui/core/styles';
import * as quotationApi from '../api/quotation-api';
import * as deliveryApi from '../api/delivery-api';
import * as dictionaryApi from '../api/dictionary-api';
import { parseQuotationExcel } from '../utils/excel';
import { saveExcel } from '../utils/excel';
import { getCookie } from '../utils/cookies';
import { setCookie } from '../utils/cookies';
import { useNotificationContext } from '../utils/userNotificationContext';
import { useFile } from '../utils/fileContext';
import { useSearchContext } from '../utils/searchContext';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import {
  Button,
  Tabs,
  Tab,
  Typography,
  Grid,
  Divider,
  ThemeProvider,
  TablePagination,
  useTheme,
} from '@material-ui/core';
import {
  Page,
  SearchInput,
  SearchTable,
  GroupSearchTable,
  ActionsGroup,
  Select,
  SelectFine,
  Spinner,
  SearchHelpDialog,
} from '../components';
import { Link, useLocation } from 'react-router-dom';

import delivery_icon from '../assets/image/delivery_icon.png';
import currency_icon from '../assets/image/currency_icon.png';
import calendar_icon from '../assets/image/calendar_icon.png';
import download_icon from '../assets/image/download_icon.png';
import upload_icon from '../assets/image/upload_icon.png';
import trash_icon from '../assets/image/trash_icon.png';
import AddDeliveryDialog from '../components/addDeliveryDialog';

const SearchPage = ({ classes, history, location }) => {
  const { t } = useTranslation();
  const deliveryTermLimit = [
    { title: `1 ${t('days')}`, value: 1 },
    { title: `4 ${t('days')}`, value: 4 },
    { title: `7 ${t('days')}`, value: 7 },
    { title: `14 ${t('days')}`, value: 14 },
    { title: `21 ${t('days')}`, value: 21 },
    { title: `30 ${t('days')}`, value: 30 },
    { title: `60 ${t('days')}`, value: 60 },
    { title: `180 ${t('days')}`, value: 180 },
  ];
  const { file, setFile } = useFile();
  const {
    search,
    setSearch,
    deliveryTerm,
    setDeliveryTerm,
    currency,
    deliveryTariff,
    setDeliveryTariff,
    setCurrency,
  } = useSearchContext();
  // const [isLoggedIn] = useState(!!getCookie('access_token'));
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );
  const [data, setData] = useState([]);
  const [nonEmptyData, setNonEmptyData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [workingData, setWorkingData] = useState([]);
  const [itemsWithNullPrice, setItemsWithNullPrice] = useState([]);

  const [groupGroupConfirmedData, setGroupGroupConfirmedData] = useState({});

  const [tab, setTab] = useState(0);
  const [isFileUploading, setFileUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [AddDeliveryError, setAddDeliveryError] = useState('');
  const [isAddDeliveryDialogOpen, setAddDeliveryDialogOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [deliveryTariffList, setDeliveryTariffList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [lastQuotationId, setLastQuotationId] = useState(0);
  const initialized = useRef(false);
  const [showWarn, setShowWarn] = useState(0);

  const [progress, setProgress] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const theme = useTheme();
  const [userDelivery, setUserDelivery] = useState({
    tariff: '',
    active: true,
    countryCode: '',
    city: '',
    province: '',
    zipCode: '',
    street: '',
    building: '',
    flat: '',
    addInfo: '',
    carrierId: 0,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { fetchCartData } = useNotificationContext();

  async function getDeliveryTariffList() {
    const result = await deliveryApi.deliveries(true, null);
    setDeliveryTariffList(
      result.map((row) => ({
        title: row['text'],
        value: row['id'],
        local: row['tariffLocal'],
        tariff: row['tariff'],
      }))
    );

    if (result.length !== 0 && deliveryTariff === 0)
      setDeliveryTariff(result[0].id);
  }

  async function getCurrencyList() {
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['code'],
        value: row['code'],
      }))
    );

    if (result.length !== 0) setCurrency(result[0].code);
  }

  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  const openFileDialog = useRef();

  const columns = useMemo(() => [
    { name: 'Brand', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Substituted', width: 15 },
    { name: 'Description', width: 20 },
    { name: 'Quantity', width: 10 },
    { name: 'Price', width: 10 },
    { name: 'WeightKg', width: 10 },
    { name: 'Reference', width: 15 },
    { name: 'Booking' },
    { name: 'Days' },
    { name: 'Currency' },
    { name: 'yourPrice' },
    //    { name: 'Available' },
    { name: 'yourDays' },
    { name: 'Comment' },
    { name: 'StockInfo' },
  ]);

  const outputData = useMemo(
    () =>
      data
        .filter((value) => value.confirmed)
        .map((value) => [
          value.brand,
          value.partNumber,
          value.price && value.inpPartNumber !== value.partNumber
            ? value.inpPartNumber
            : '',
          value.description,
          value.quantity /* &&
          && (!value.available || value.quantity <= value.available)
            ? value.quantity
            : value.available,*/,
          value.price,
          value.weightKg,
          value.yourReference,
          value.booking,
          value.days,
          value.currency,
          value.price && value.inpPrice < value.price ? value.inpPrice : '',
          /*
          value.price && value.inpQuantity > value.available
            ? value.inpQuantity
            : '',
          */
          value.price && value.inpDays < value.days ? value.inpDays : '',
          value.errDesc || '',
          value.stock,
        ]),
    [data]
  );

  useEffect(() => {
    if (data.some((item) => item.price != null)) {
      setDisplayTable(true);
      setGroupedData(groupBy(data, 'groupId'));
    } else {
      setDisplayTable(false);
    }
    setItemsWithNullPrice(data.filter((item) => item.price === null));
    const result = data.filter((item) => item.price !== null);
    //console.log(itemsWithNullPrice);
    //console.log(nonEmptyData);
    setNonEmptyData(result);
  }, [data]);

  useEffect(() => {
    let initialGroupConfirmedData = {};
    for (let groupId in groupedData) {
      initialGroupConfirmedData[groupId] = groupedData[groupId].filter(
        (item) => item.confirmed
      );
    }
    setGroupGroupConfirmedData(initialGroupConfirmedData);
  }, [groupedData]);

  useEffect(() => {
    if (file) {
      parseQuotationExcel(file)
        .then((data) => {
          setWorkingData(data);
          setTab(1);
          setSelectedFile(file);
        })
        .catch((err) => {
          //  console.log(err);
        });
    }
  }, [file]);

  useEffect(() => {
    if (tab === 1 && file) {
      fetchData(
        workingData.map((row) => ({
          partNumber: row['PartNumber'],
          brand: row['Brand'],
          quantity: row['Quantity'],
          price: row['Price'],
          yourReference: row['Reference'],
          booking: row['Booking'],
          delivery: deliveryTariff,
        })),
        deliveryTerm,
        true
      ).then();
    }
  }, [tab]);

  useEffect(() => {
    setLastQuotationId(null);
    setData([]);
    if (deliveryTariff) fetchDataBySearch();
  }, [location.search, deliveryTariff]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (!deliveryTariffList || !deliveryTariffList.length)
        getDeliveryTariffList().then();

      if (!currencyList || !currencyList.length) getCurrencyList().then();

      if (!countryList || !countryList.length) getCountryList().then();
    }
  }, []);

  useEffect(() => {
    fetchOnly().then();
  }, [currency]);

  // useEffect(() => {
  //   setLastQuotationId(null);
  //   setData([]);
  // }, [deliveryTerm, tab]);

  async function fetchData(query, maxDays, onlyBest) {
    setData([]);
    setShowWarn(0);

    let result = [];

    setProgress(true);
    try {
      result = await quotationApi.process(query, maxDays, onlyBest, currency);
    } finally {
      setProgress(false);
    }

    let warn = 0;
    result.forEach((item) => {
      item.confirmed = item.err >= 0;
      if (warn < item.err) warn = item.err;
    });
    setShowWarn(warn || !isLoggedIn);
    setData(result);

    if (result && result.length) {
      const { quotationId } = result[0];
      setLastQuotationId(quotationId);
    }
  }

  async function fetchOnly() {
    if (lastQuotationId) {
      let warn = 0;
      let result = [];

      setProgress(true);
      try {
        result = await quotationApi.select(lastQuotationId, currency);

        result.forEach((item) => {
          const oldItem = data.find((old) => old.id === item.id);
          if (oldItem) {
            item.confirmed = oldItem.confirmed;
            item.quantity = oldItem.quantity;

            if (item.err > warn) warn = item.err;
          }
        });
      } catch (e) {}

      setProgress(false);
      setShowWarn(warn);
      setData(result);
    }
  }

  function handleChangeCurrency(value) {
    setCurrency(value);
    setCookie('currency', value);
  }

  function fetchDataBySearch() {
    const params = querystring.parse(location.search.slice(1));
    if (params.part !== undefined) {
      setSearch(params.part);
      fetchData(
        [{ partNumber: params.part, delivery: deliveryTariff }],
        0,
        false
      ).then();
      setSelectedFile(null);
      setFile(null);
    }
  }

  function handleSearchInputChange(value) {
    setSearch(value);
  }

  function formatInput(value) {
    //    const numbers = value.replace(/\D/g, ''); // remove all non-digit characters ????
    return value.replace(/(\d{2})(?=\d)/g, '$1 '); // add space every two digits
  }

  function handleSearchInputSearch() {
    const params = querystring.parse(location.search.slice(1));

    const formattedSearch = search.replace(/\s/g, ''); // Remove spaces from the search input

    if (params.part === formattedSearch) {
      fetchDataBySearch();
    } else {
      history.push({
        pathname: '/search',
        search: `?part=${formattedSearch}`,
      });
    }
  }

  function handleDataChange(data) {
    setData(data);
  }

  async function handleTableSelect(id) {
    const newData = data.map((item) => {
      return item.id !== id ? item : { ...item, confirmed: !item.confirmed };
    });
    setData(newData);
  }

  async function handleTableSelectAll(confirmed) {
    const newData = data.map((item) => {
      return { ...item, confirmed };
    });
    setData(newData);
  }

  async function handleToBasket() {
    if (isLoggedIn)
      try {
        const { quotationId } = data[0];
        if (quotationId && quotationId > 0) {
          await quotationApi.confirmAndToBasket(
            quotationId,
            data.filter((value) => value.confirmed && value.quantity > 0)
          );
          setData([]);
          setLastQuotationId(null);
        }
      } catch (e) {
        // silence
      }
  }

  function handleGroupTableSelect(id, groupId) {
    let groupData = [...(groupGroupConfirmedData[groupId] || [])];
    let itemIndex = groupData.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      // Item is in the group confirmed data and the checkbox has been unchecked. Remove the item from the array and set confirmed to false.
      groupData[itemIndex].confirmed = false;
      groupData.splice(itemIndex, 1);
    } else {
      // Checkbox has been checked. Find the item, set confirmed to true, and add the item to the group confirmed data.
      let item = groupedData[groupId].find((item) => item.id === id);
      item.confirmed = true;
      groupData.push(item);
    }
    setGroupGroupConfirmedData({
      ...groupGroupConfirmedData,
      [groupId]: groupData,
    });
  }

  function handleGroupTableSelectAll(isAllSelected, groupId) {
    let groupData = Array.isArray(groupedData[groupId])
      ? [...groupedData[groupId]]
      : [];

    if (isAllSelected) {
      groupData = groupData.map((item) => ({
        ...item,
        confirmed: true,
      }));
    } else {
      groupData = groupData.map((item) => ({
        ...item,
        confirmed: false,
      }));
    }

    setGroupedData((prevGroupedData) => ({
      ...prevGroupedData,
      [groupId]: groupData,
    }));

    setGroupGroupConfirmedData((prevGroupGroupConfirmedData) => ({
      ...prevGroupGroupConfirmedData,
      [groupId]: isAllSelected ? [...groupData] : [],
    }));
  }

  async function handleGroupToBasket() {
    if (isLoggedIn) {
      try {
        let allConfirmedData = Object.values(groupGroupConfirmedData).reduce(
          (acc, curr) => acc.concat(curr),
          []
        );

        let confirmedData = allConfirmedData.filter(
          (item) => item.quantity > 0
        );

        if (confirmedData.length > 0) {
          const { quotationId } = confirmedData[0];
          if (quotationId && quotationId > 0) {
            await quotationApi.confirmAndToBasket(quotationId, confirmedData);
            fetchCartData();
          }
        }

        // Reset the data after successful API call.
        setData([]);
        setGroupedData([]);
        setGroupGroupConfirmedData({});
      } catch (e) {}
    }
  }

  function handleGroupDataChange(groupId, updatedData) {
    setGroupedData((prevGroupedData) => {
      const updatedGroupedData = {
        ...prevGroupedData,
        [groupId]: updatedData,
      };

      const updatedGroupGroupConfirmedData = {
        ...groupGroupConfirmedData,
        [groupId]: updatedData
          ? updatedData.filter((item) => item.confirmed)
          : [],
      };

      // Update both states together
      setGroupGroupConfirmedData(updatedGroupGroupConfirmedData);

      // Return the new state for groupedData
      return updatedGroupedData;
    });
  }

  async function handleToExcel() {
    await saveExcel('Quotation', columns, outputData);
  }

  function handleTabChange(event, value) {
    setTab(value);
  }

  function handleFileOpen() {
    openFileDialog.current.click();
  }

  function handleAddDeliveryDialogClose() {
    setAddDeliveryDialogOpen(false);
  }

  function handleAddDeliveryDialogOpen() {
    setAddDeliveryDialogOpen(true);
  }

  async function handleAddDelivery(deliveryData) {
    setUserDelivery(deliveryData);
    await deliveryApi.addDelivery(deliveryData);
    getDeliveryTariffList();
  }

  function handleDeliveryTermChange(value) {
    setDeliveryTerm(value);
  }

  function handleDeliveryTariffChange(value) {
    setDeliveryTariff(value);
  }

  async function handleQuotationExcel(event) {
    setFileUploading(true);
    setProgress(true);
    let excelData = [];
    try {
      const file = event.target.files[0];
      event.target.value = '';
      if (!file) return;
      setSelectedFile(file);
      excelData = await parseQuotationExcel(file);
    } catch (err) {
      alert(err.message);
    }

    if (!excelData.length) {
      setProgress(false);
      setFileUploading(false);
      return;
    }
    try {
      await fetchData(
        excelData.map((row) => ({
          partNumber: row['PartNumber'],
          brand: row['Brand'],
          quantity: row['Quantity'],
          price: row['Price'],
          yourReference: row['Reference'],
          booking: row['Booking'],
          delivery: deliveryTariff,
        })),
        deliveryTerm,
        true
      );
      setProgress(false);
      setFileUploading(false);
    } catch (err) {
      setProgress(false);
      alert(t('error_quotation'));
      setFileUploading(false);
    }
  }

  function handleDeleteFile(event) {
    setSelectedFile(null);
    setFile(null);
    setData([]);
  }

  function handleHelpDialog() {
    setHelpDialogOpen(!helpDialogOpen);
  }

  function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  return (
    <Page>
      <div>
        <Helmet>
          <title>
            Search -AllCarParts - Find out car spare parts, you are looking for
            - availability, prices and delivery options
          </title>
          <meta
            name="description"
            content="Search by a single part number or get quotation for the entire list of part numbers, with possible cross references"
          ></meta>
        </Helmet>
      </div>
      <>
        <Grid container alignItems="center" className={classes.searchContainer}>
          <Grid item lg={3} sm={6} className={classes.gridItem}>
            <div className={classes.items}>
              <Typography variant="h5" className={classes.searchHeading}>
                {t('choose_search')}
              </Typography>
              <Tabs
                classes={{
                  root: classes.tabs,
                  indicator: classes.indicator,
                }}
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    marginLeft: tab === 0 ? '2px' : '-2px',
                  },
                }}
              >
                <Tab
                  disableRipple
                  label={
                    <StyledTypography>{t('by_part_number')}</StyledTypography>
                  }
                  classes={{ root: classes.tabRoot }}
                  style={{
                    borderRadius: '10px 0 0 10px',
                  }}
                />
                <Tab
                  disableRipple
                  label={
                    <StyledTypography>{t('by_excel_file')}</StyledTypography>
                  }
                  classes={{ root: classes.tabRoot }}
                  style={{
                    borderRadius: '0 10px 10px 0',
                  }}
                />
              </Tabs>
            </div>
          </Grid>
          <Grid item lg={3} sm={6} className={classes.gridItem}>
            {tab === 0 ? (
              <div className={classes.items}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t('provide_part_number')}
                </Typography>
                <SearchInput
                  // classes={{ lang: classes.selectField }}
                  value={search}
                  onChange={handleSearchInputChange}
                  onSearch={handleSearchInputSearch}
                />
              </div>
            ) : (
              <div className={classes.items}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t('choose_delivery_method')}
                </Typography>
                <SelectFine
                  classes={{ lang: classes.selectField }}
                  items={deliveryTariffList}
                  value={deliveryTariff}
                  onChange={handleDeliveryTariffChange}
                  icon={delivery_icon}
                  userDelivery={isLoggedIn}
                  onAddDelivery={handleAddDeliveryDialogOpen}
                />
              </div>
            )}
          </Grid>
          <Grid item lg={3} sm={6} className={classes.gridItem}>
            {tab === 0 ? (
              <div className={classes.items}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t('choose_delivery_method')}
                </Typography>
                <SelectFine
                  classes={{ lang: classes.selectField }}
                  items={deliveryTariffList}
                  value={deliveryTariff}
                  onChange={handleDeliveryTariffChange}
                  icon={delivery_icon}
                  userDelivery={isLoggedIn}
                  onAddDelivery={handleAddDeliveryDialogOpen}
                />
              </div>
            ) : (
              <div className={classes.items}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t('choose_currency')}
                </Typography>
                <SelectFine
                  classes={{ lang: classes.selectField }}
                  items={currencyList}
                  value={currency}
                  onChange={handleChangeCurrency}
                  icon={currency_icon}
                />
              </div>
            )}
          </Grid>
          <Grid item lg={3} sm={6} className={classes.gridItem}>
            {tab === 0 ? (
              <div className={classes.items}>
                <Typography variant="h5" className={classes.searchHeading}>
                  {t('choose_currency')}
                </Typography>
                <SelectFine
                  classes={{ lang: classes.selectField }}
                  items={currencyList}
                  value={currency}
                  onChange={handleChangeCurrency}
                  icon={currency_icon}
                />
              </div>
            ) : (
              <div></div>
            )}
          </Grid>
        </Grid>

        {tab != 0 ? (
          <>
            <Divider variant="middle" />
            <Grid container className={classes.searchContainerByFile}>
              <Grid item lg={4}>
                <div className={classes.fileButtons}>
                  <Typography variant="h5" className={classes.fileHeadings}>
                    {t('upload_csv_file')}
                  </Typography>

                  {selectedFile ? (
                    <div className={classes.uploadedFile}>
                      <img
                        src={trash_icon}
                        className={classes.iconDelete}
                        onClick={handleDeleteFile}
                      />
                      <Typography variant="h5" className={classes.fileHeadings}>
                        {selectedFile.name}
                      </Typography>
                    </div>
                  ) : (
                    <Button
                      disabled={isFileUploading}
                      className={classes.leftButton}
                      variant="contained"
                      onClick={handleFileOpen}
                      startIcon={
                        <img src={upload_icon} className={classes.icon} />
                      }
                    >
                      {t('upload')}
                    </Button>
                  )}

                  <input
                    ref={openFileDialog}
                    type="file"
                    hidden
                    style={{ height: 0, width: 0 }}
                    accept=".xlsx"
                    onChange={handleQuotationExcel}
                  />
                </div>
                <div className={classes.fileButtons}>
                  <Typography variant="h5" className={classes.fileHeadings}>
                    {t('template')}
                  </Typography>
                  <Button
                    className={classes.rightButton}
                    variant="contained"
                    component="a"
                    href="/quote_template.xlsx"
                    download
                    startIcon={
                      <img src={download_icon} className={classes.icon} />
                    }
                  >
                    {t('download')}
                  </Button>
                </div>
              </Grid>
              <Grid item lg={2}>
                <div className={classes.deliveryTermContainer}>
                  <Typography variant="h6" className={classes.searchHeading}>
                    {t('max_days')}
                  </Typography>
                  <SelectFine
                    classes={{ lang: classes.selectField }}
                    items={deliveryTermLimit}
                    value={deliveryTerm}
                    onChange={handleDeliveryTermChange}
                    icon={calendar_icon}
                  />
                </div>
              </Grid>
              <Grid item lg={6}></Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>

      {showWarn ? (
        <div className={classes.warnPanel}>
          <Typography className={classes.warnLabel}>
            {!isLoggedIn ? (
              <div>{t('demo_prices_log_in')}</div>
            ) : (
              <div>{showWarn === 5 ? t('quotation_differs') : null}</div>
            )}
          </Typography>
        </div>
      ) : null}

      {displayTable ? (
        <ActionsGroup className={classes.actionsGroup}>
          <Button
            disabled={!data.length || isFileUploading || !isLoggedIn}
            className={classes.basketButton}
            variant="contained"
            color="primary"
            onClick={selectedFile ? handleToBasket : handleGroupToBasket}
          >
            {t('to_cart')}
          </Button>

          <Button
            disabled={!data.length || isFileUploading}
            className={classes.excelButton}
            variant="contained"
            color="default"
            onClick={handleToExcel}
          >
            {t('export')}
          </Button>
        </ActionsGroup>
      ) : null}

      {progress ? (
        <div className={classes.spinnerContainer}>
          {progress ? <Spinner /> : null}
        </div>
      ) : displayTable ? (
        <div className={classes.tableContainer}>
          {selectedFile != null ? (
            <SearchTable
              data={nonEmptyData}
              showReference={tab === 1}
              onDataChange={handleDataChange}
              onSelect={handleTableSelect}
              onSelectAll={handleTableSelectAll}
              isLoggedIn={isLoggedIn}
              page={page}
              noSplit
            />
          ) : (
            Object.keys(groupedData).map((groupId) => {
              if (groupedData[groupId] && groupedData[groupId].length > 0) {
                return (
                  <div key={groupId}>
                    <span className={classes.tableHeading}>
                      <h3>{groupId}</h3>
                      <h3>•</h3>
                      <h3>{groupedData[groupId][0].groupBrand}</h3>
                      <h3>•</h3>
                      <h3> {groupedData[groupId][0].groupDescription}</h3>
                    </span>
                    <GroupSearchTable
                      data={groupedData[groupId]}
                      groupId={groupId}
                      showReference={tab === 1}
                      onDataChange={handleGroupDataChange}
                      onSelect={handleGroupTableSelect}
                      onSelectAll={handleGroupTableSelectAll}
                      isLoggedIn={isLoggedIn}
                      confirmedData={groupGroupConfirmedData[groupId]}
                      groupMethod={groupBy}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })
          )}
        </div>
      ) : nonEmptyData.length ? (
        itemsWithNullPrice.map((item, index) => (
          <div className={classes.warnContainer}>
            <Typography variant="h6" className={classes.warnMessage}>
              {t('nothing_found', { item: item.partNumber })}
            </Typography>
          </div>
        ))
      ) : data.length ? (
        <div className={classes.warnContainer}>
          <Typography variant="h6" className={classes.helpMessage}>
            {t('no_results_found')}
            <button className={classes.helpButton} onClick={handleHelpDialog}>
              {t('here')}
            </button>
          </Typography>
        </div>
      ) : null}
      {data.length && tab === 1 ? (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={nonEmptyData.length}
          rowsPerPage={500}
          page={page}
          backIconButtonProps={{ className: classes.paginationButton }}
          nextIconButtonProps={{ className: classes.paginationButton }}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count, page }) =>
            `${t('page')} ${page + 1} / ${Math.floor(count / 500) + 1}`
          }
          style={{ margin: '0 auto' }}
        />
      ) : null}

      {helpDialogOpen && <SearchHelpDialog closeAction={handleHelpDialog} />}

      {isAddDeliveryDialogOpen && (
        <AddDeliveryDialog
          error={AddDeliveryError}
          onClose={handleAddDeliveryDialogClose}
          onSubmit={handleAddDelivery}
          data={userDelivery}
          deliveryTariffList={deliveryTariffList}
          countryList={countryList}
        />
      )}
    </Page>
  );
};

/*
<SearchFilter
  filter={filter}
  disabled={!data.length}
  onFilterChange={handleFilterChange}
/>
*/

const StyledTypography = withStyles((theme) => ({
  root: {
    color: '#000',
    zIndex: 1000,
    fontWeight: 500,
    fontSize: '12px',
    ...theme.typography, // apply your theme's typography here
  },
}))(Typography);

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  const searchContainer = {
    backgroundColor: '#F2F2F7',
    minHeight: '200px',
    [theme.breakpoints.up('nr')]: {
      minHeight: '125px',
    },
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };
  return {
    tabs: {
      minWidth: '270px',
      maxWidth: '275px',
      minHeight: '32px',
    },
    tabRoot: {
      backgroundColor: '#7676801F',
      opacity: 1,
      minHeight: '32px',
      [theme.breakpoints.up('sm')]: {
        width: '50%',
        height: '32px',
      },
    },
    tabSelected: {
      color: '#000',
    },
    tabContainer: {},
    indicator: {
      marginBottom: '2px',
      zIndex: '0',
      height: '88%',
      borderRadius: '10px',
      backgroundColor: '#fff',
      transition: 'left 0.3s',
      boxShadow:
        '0px 2.25px 6px rgba(0, 0, 0, 0.12), 0px 2.25px 0.75px rgba(0, 0, 0, 0.04)',
    },
    searchHeading: {
      maxWidth: '265px',
      fontWeight: '500',
      fontSize: '18px',
      paddingBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2px',
      },
    },
    tableHeading: {
      display: 'flex',
      gap: '20px',
      '& > *': {
        fontSize: '18px',
        fontWeight: '500',
      },
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    gridItem: {
      [theme.breakpoints.up('nr')]: {
        flexBasis: '25% !important',
        maxWidth: '25% !important',
      },
      [theme.breakpoints.down('nr')]: {
        flexBasis: '50% !important',
        maxWidth: '50% !important',
      },
    },
    searchContainer: {
      ...searchContainer,
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '30px',
        justifyContent: 'flex-start',
        minHeight: '200px',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '400px',
        paddingLeft: '30px',
        justifyContent: 'flex-start',
      },
    },
    searchContainerByFile: {
      ...searchContainer,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: '210px',
        paddingLeft: '30px',
        justifyContent: 'flex-start',
      },
    },
    paginationButton: {
      color: '#F2F2F7',
      backgroundColor: '#007AFF',
      padding: '5px',
      margin: '0 2px',
      '&:hover': {
        backgroundColor: '#007AFF90',
      },
    },
    fileButtons: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '18px',
      marginTop: '16px',
      marginRight: '16px',
    },
    fileHeadings: {
      textAlign: 'left',
      fontSize: '16px',
      fontWeight: '400',
      marginLeft: '10px',
    },
    uploadedFile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    icon: {
      height: '14px',
      width: '14px',
    },
    iconDelete: {
      height: '32px',
      width: '32px',
      backgroundColor: '#FF6961',
      borderRadius: '10px',
      cursor: 'pointer',
      transition: '0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#FF392F',
      },
    },
    deliveryTermLabel: {
      color: colors.deliveryTermLabel,
      marginRight: theme.spacing(1),
    },
    actionsGroup: {
      justifyContent: 'space-between',
      margin: '2rem 3rem',
    },
    selectField: {
      backgroundColor: '#ffffff',
      minWidth: '265px',
      height: '32px',
    },
    basketButton: {
      ...theme.button.filled,
      minWidth: '140px',
      height: '32px',
    },

    excelButton: {
      ...theme.button.transparent,
      minWidth: '140px',
      height: '32px',
    },

    tableContainer: {
      padding: '0 1rem',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      height: 'calc(100vh - 250px)',
    },
    spinnerContainer: {
      ...searchContainer,
      display: 'flex',
      marginTop: theme.spacing(1) * 6,
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    nextContainer: {
      marginLeft: theme.spacing(1),
    },
    downContainer: {
      marginTop: theme.spacing(1),
    },
    leftButton: {
      width: '162px',
      height: '32px',
      borderRadius: '10px',
      textTransform: 'capitalize',
      backgroundColor: '#007AFF',
      color: '#ffffff',
    },
    rightButton: {
      width: '162px',
      height: '32px',
      borderRadius: '10px',
      textTransform: 'capitalize',
      backgroundColor: '#007AFF15',
      color: '#007AFF',
    },
    whiteControl: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&:hover .MuiOutlinedInput-input': {
        color: 'red',
      },
    },
    warnPanel: {
      borderRadius: '7px',
      backgroundColor: '#F2F2F7',
      textAlign: 'center',
      minHeight: 30,
      padding: '5px',
      marginBottom: theme.spacing(1),
    },
    warnLabel: {
      textTransform: 'uppercase',
      color: '#FF3B30',
    },
    warnContainer: {
      textAlign: 'center',
      padding: '20px 0',
      backgroundColor: '#7676801F',
      width: '90%',
      borderRadius: '20px',
      margin: '.25rem auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        borderRadius: '0px',
      },
    },
    warnMessage: {
      fontWeight: 400,
      color: '#FF3B30',
    },
    helpMessage: {
      fontWeight: 400,
      color: '#000000',
    },
    helpButton: {
      fontSize: '1rem',

      padding: '1px',
      textDecoration: 'underline',
      border: 'none',
      cursor: 'pointer',
      color: '#007AFF',
      '& > *': {
        fontWeight: 500,
      },
    },
  };
};

export default withStyles(styles)(SearchPage);
